import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import './styles.less';

import { Modal, Button, Table, Card, Row, Col, Tag, Checkbox } from 'antd';
import { PlusOutlined, StarFilled } from '@ant-design/icons';

import { categoryList, categoryDetail } from 'apis/categories';
import CategoryCreateForm from './forms/category-create.form';
import CategoryUpdateForm from './forms/category-update.form';

import useToken from 'hooks/useToken';
import moment from 'moment';

const Category = () => {
  const { token } = useToken();
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [category, setCategory] = useState({});
  const [categories, setCategories] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (token) {
      categoryList(token)
        .then(result => {
          setCategories(result)
          setCategoriesFilter(result)
          setLoading(false)
        });
    }
  }, [token]);

  const openUpdateModal = (categoryId) => {
    const category = categories.find(item => item.categoryId === categoryId);
    setCategory(category);
    setIsUpdateModal(true);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {
      title: 'Sort',
      align: 'center',
      dataIndex: 'no',
      width: '3%',
    },
    {
      title: 'Name',
      width: '15%',
      render: (record) => {
        return (
          <div>
            {record.isPopular === true && (<StarFilled style={{ marginRight: 8, color: '#4a90e2' }} />)}
            {record.name}
          </div>
        )
      }
    },
    {
      title: 'Parent',
      dataIndex: 'categoryParentId',
      width: '15%',
      render: (record) => {
        const obj = _.find(categories, { categoryId: record });
        return (
          <Tag color='red' key={_.get(obj, 'categoryId', '')}>{_.get(obj, 'name', '')}</Tag>
        );
      },
      filters: categories.filter(x => x.categoryParentId === '').map(item => {
        return {
          text: item.name,
          value: item.categoryId,
        }
      }),
      onFilter: (value, record) => record.categoryParentId === value,
    },
    {
      title: 'Last Update',
      align: 'center',
      dataIndex: 'updatedAt',
      width: '7%',
      render: (text, record, index) => {
        return (
          `${moment(record.createdAt).format('YYYY-MM-DD')}`
        );
      }
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      align: 'center',
      width: '4%',
      render: (record) => {
        if (record === true)
          return (
            <Tag>Active</Tag>
          )
        else {
          return (
            <Tag color={'red'}>In-active</Tag>
          )
        }
      }
    },
    {
      title: '',
      align: 'center',
      width: '6%',
      render: (record) => {
        return (
          <Button type="primary" onClickCapture={() => openUpdateModal(record.categoryId)}>Edit</Button>
        );
      }
    }
  ];

  return (
    <>
      <Card style={{ marginBottom: 18 }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
          <Col className="gutter-row" span={12}>
            <h2>Category</h2>
            <Checkbox onChange={(e) => {
              if (e.target.checked === true) {
                setCategoriesFilter(categories.filter(item => item.categoryParentId === ''))
              } else {
                setCategoriesFilter(categories)
              }
            }}>Category Parent</Checkbox>
          </Col>
          <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={() => setIsCreateModal(true)} icon={<PlusOutlined />}>Thêm mới</Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={loading}
        bordered
        columns={columns}
        dataSource={categoriesFilter}
        onChange={(pagination, filters, sorter) => {
          setCurrentPage(pagination.current);
        }}
        pagination={{
          total: categories.lenght,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          pageSize: 20,
          current: currentPage
        }}
      />
      <Modal
        visible={isCreateModal}
        title="Add New a Category"
        onCancel={() => setIsCreateModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <CategoryCreateForm
          token={token}
          onCancel={() => setIsCreateModal(false)}
          categories={categories}
          onCallback={(categoryId) => {
            setLoading(true)

            categoryList(token)
              .then(result => {
                setCategories(result)
                setCategoriesFilter(result)
                setLoading(false)
              });

            setIsCreateModal(false)
          }}
        />
      </Modal>
      <Modal
        visible={isUpdateModal}
        title="Update Category"
        onCancel={() => setIsUpdateModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <CategoryUpdateForm
          token={token}
          category={category}
          onCancel={() => setIsUpdateModal(false)}
          categories={categories}
          onCallback={(categoryId) => {
            setLoading(true)

            categoryList(token)
              .then(result => {
                setCategories(result)
                setCategoriesFilter(result)
                setLoading(false)
              });

            setIsUpdateModal(false)
          }}
        />
      </Modal>
    </>
  )
};

export default Category;