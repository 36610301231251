import { db } from 'utils/firebase';
import { collection, query, doc, orderBy, getDocs, getDoc, addDoc, updateDoc, serverTimestamp  } from "firebase/firestore";

export const tagList = async () => {
  const q = query(collection(db, "tags"), orderBy('updatedAt', 'desc'));
  const querySnapshot = await getDocs(q);

  let tags = [];
  querySnapshot.forEach((doc) => {
    tags.push({
      tagId: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt.toDate(),
      updatedAt: doc.data().updatedAt.toDate(),
    });
  });
  return tags;
};

export const tagDetail = async (payload) => {
  const docRef = doc(db, "tags", payload?.tagId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      ...docSnap.data(), 
      tagId: docSnap.id,
    }
  } else {
    return undefined
  }
};

export const tagCreate = async (payload, token) => {
  try {
    const docRef = await addDoc(collection(db, "tags"), {
      ...payload,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
    return {
      status: 'success',
      id: docRef.id
    };
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};

export const tagUpdate = async (payload) => {
  try {
    const tagId = payload?.tagId;
    delete payload.tagId;
    
    await updateDoc(doc(db, "tags", tagId), {
      ...payload,
      updatedAt: serverTimestamp(),
    });
    return {
      status: 'success',
      id: tagId
    };
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};