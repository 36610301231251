import React, { useState, useMemo, useRef } from 'react';
import { Form, Input, Col, Row, Button, Avatar, Select, Checkbox, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { authorCreate } from 'apis/authors';

import ReactQuill, {Quill} from 'react-quill';
import '../../../../../node_modules/react-quill/dist/quill.snow.css';

import { QuillToolbarContainer, QuillFormats } from 'utils/quill';
import { uploadFile } from 'apis/other';

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const AuthorCreateForm = ({ token, onCancel, onCallback }) => {
  const [form] = Form.useForm();
  const [body, setBody] = useState('');
  const quillRef = useRef(null);

  const imageHandler = () => {
    const quillEditor = quillRef.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input.files[0]
      
      uploadFile(file)
      .then(url => {
        const range = quillEditor.getSelection();
        quillEditor.insertEmbed(range.index, 'image', url)
      })
      .catch(error => console.log(error));
    }
  }

  const modules = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
      handlers: {
        'image': imageHandler
      }
    },
    clipboard: {
      matchVisual: false
    }
  }), [])
  

  const [photo, setPhoto] = useState('');
  const [isUploadPhoto, setIsUploadPhoto] = useState(false);
  const [photoPreview, setPhotoPreview] = useState('');

  const handleChange = name => e => {
    if (name === 'photo') {
      setPhoto(e.target.files[0]);
      setIsUploadPhoto(true);
      setPhotoPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <Form
        form={form}
        layout="vertical"
        name="authorCreateForm"
        onFinish={async values => {
          let item = {}

          if (values?.name) {
            item.name = values?.name;
          }

          if (values?.lang) {
            item.lang = values?.lang;
          }

          if (body) {
            item.body = body;
          } else {
            item.body = '';
          }

          if (values?.isActive) {
            item.isActive = values?.isActive;
          } else {
            item.isActive = false;
          }

          if (isUploadPhoto) {
            let url = await uploadFile(photo);
            item.photo = url;
          }
  
          let result = await authorCreate(item);
          if (result.status === 'success') {
            onCancel()
            onCallback()
          } else {
            message.error(result?.error?.message);
          }
        }}
        initialValues={{
          lang: '',
          name: '',
          body: '',
        }}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 18 }} xs={{ span: 24 }}>
            <Form.Item 
              name="name" 
              label="Author Name" 
              rules={[
                {
                  required: true,
                  message: 'Please input the Name!'
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item 
              name="lang"
              label="Language"
            >
              <Select
                showSearch
                placeholder="Language"
              >
                <Select.Option value={'vn'}>vn</Select.Option>
                <Select.Option value={'en'}>en</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item label="Mô tả">
              <ReactQuill
                ref={quillRef}
                modules={modules}
                formats={QuillFormats}
                placeholder=""
                onChange={(value) => setBody(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item label="Photo" >
              <label htmlFor="photo" className="ant-btn" style={{paddingTop: 5}}><UploadOutlined /> Chọn photo</label>
              <Input
                id='photo'
                name="photo"
                placeholder="Photo"
                accept="image/*" 
                type="file"
                hidden
                onChange={handleChange('photo')}
              />
              <div style={{ alignItems: 'center', marginTop: 10}}>
                <Avatar shape="square" size={128} src={photoPreview !== '' ? photoPreview : ''} />
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item name="isActive" label="Active" valuePropName="checked">
              <Checkbox>Active</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              <Button type="clear" onClick={onCancel}>
                  Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                  Save
              </Button>
            </Col>
          </Row>
        </div>
  
      </Form>
    
  );
};

export default AuthorCreateForm;