/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Layout, Table, Row, Col, Card, Tag } from 'antd';

import useToken from 'hooks/useToken';
import { gumroadSaleList } from 'apis/gumroad';
import moment from 'moment';

import './styles.less';

const Sales = () => {
  const [searchParams] = useSearchParams();
  const { token } = useToken();
  const [loading, setLoading] = useState([]);

  console.log('searchParams: ', searchParams?.get('email'))

  const [items, setItems] = useState([])

  useEffect(() => {
    (async () => {
      try {
        //setLoading(true)
        if (token) {
          //fetch data
          setLoading(true)
          const [sales] = await Promise.all([
            gumroadSaleList({
              product_id: process.env.REACT_APP_PRODUCT_ID, 
              email: searchParams?.get('email'),
            }, token)
          ]);

          console.log('sales: ', sales)
          setItems(sales?.sales)
          
          setLoading(false)
        }
      } catch (e) {
        setLoading(false)
      }
    })();
  }, [token])

  console.log('items: ', items)

  const columns = [
    {
      title: 'Purchase Date',
      align: 'center',
      dataIndex: 'created_at',
      width: '20%',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.created_at).format('YYYY-MM-DD')}</div>
          </div>
        );
      }
    },
    {
      title: 'Purchase Email',
      width: '18%',
      dataIndex: 'purchase_email'
    },
    {
      title: 'Email',
      width: '18%',
      dataIndex: 'email'
    },
    {
      title: 'Price',
      width: '18%',
      dataIndex: 'formatted_total_price'
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Sales (Gumroad)</h2>
            </Col>
          </Row>
        </Card>

        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          pagination={{
            defaultPageSize: 20
          }}
        />
      </Layout>
    </Layout>

  )
};

export default Sales;