/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';

import { Layout, Button, Table, DatePicker, Row, Col, Input, Card, Space, Radio, message, Select } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import _ from 'lodash';
import moment from 'moment';

import { Line } from '@ant-design/charts';

import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from "recharts";

import './styles.less';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import { versionList } from 'apis/version';
import { getAllUsers } from 'apis/user';
import { getAdminAggregatedUsagesByAppUsing, getAdminAggregatedUsagesByAppVersion, getAdminAggregatedUsagesByOS, getAdminAggregatedUsages, getAdminDailyStats } from 'apis/usage';
import useAuth from 'hooks/useAuth';

const { RangePicker } = DatePicker;

const COLORS = ["#0072f0", "#2687f2", "#4d9cf5", "#73b1f7", "#99c7f9"];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, type }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const percentChart = (percent * 100).toFixed(0);

  return (
    <text x={x} y={y} fill="#000" textAnchor="middle" dominantBaseline="central">
      {percentChart >= 5 && type !== "Others" ? `${percentChart}%` : ""}
    </text>
  );
};

const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
};

const Usage = () => {
  const { user } = useAuth();
  const [loadingUsages, setLoadingUsages] = useState(false);
  const [loadingStat, setLoadingStat] = useState(false);
  const [loadingDailyStat, setLoadingDailyStat] = useState(false);

  const searchInputEl = useRef(null);

  const [users, setUsers] = useState([]);
  const [lastVisibleId, setLastVisibleId] = useState("");
  const [total, setTotal] = useState(0);
  const [usagesList, setUsagesList] = useState([]);
  const [usageDailyStats, setUsageDailyStats] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');

  const [fromDate, setFromDate] = useState(moment().subtract(7, 'days'));
  const [toDate, setToDate] = useState(moment());

  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState("Daily");
  const [appUsingsData, setAppUsingsData] = useState([]);
  const [appOsData, setAppOsData] = useState([]);
  const [appVersionData, setAppVersionData] = useState([]);

  const [appVersion, setAppVersion] = useState("all");
  const [os, setOs] = useState("all");
  const [email, setEmail] = useState("");
  const [versions, setVersions] = useState([]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <div>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? text[dataIndex].toString() : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  function onChange(dates, dateStrings) {
    if (!dates) {
      setFromDate(null);
      setToDate(null);
    }

    if (dates && dates[0]) {
      setFromDate(dates[0])
    } else {
      setFromDate()
    }

    if (dates && dates[1]) {
      setToDate(dates[1])
    } else {
      setToDate()
    }
  }

  const convertToDuration = (value) => {
    let diffInMilliSeconds = Math.round(value / 1000);

    // calculate days
    const days = Math.floor(diffInMilliSeconds / (3600 * 24));
    diffInMilliSeconds -= days * 3600 * 24;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600);
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate minutes
    const seconds = diffInMilliSeconds;

    if (days > 0) {
      return `${days < 10 ? "0" : ""}${days}:${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""
        }${seconds}`;
    }
    return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""
      }${seconds}`;
  };

  const columns = [
    {
      title: "Last usage",
      dataIndex: "timestamp",
      width: "15%",
      sorter: {
        compare: (a, b) => {
          return moment(a.timestamp * 1000) - moment(b.timestamp * 1000);
        },
      },
      defaultSortOrder: "descend",
      render: (record) => {
        return (
          <div>
            <div>{moment(record * 1000).format("YYYY-MM-DD")}</div>
          </div>
        );
      }
    },
    {
      title: "Email",
      width: "15%",
      ...getColumnSearchProps("email")
    },
    {
      title: "Duration",
      dataIndex: "duration",
      width: "12%",
      sorter: {
        compare: (a, b) => a.duration.localeCompare(b.duration),
      },
      render: (record) => {
        return (
          <div>
            <div>{convertToDuration(record)}</div>
          </div>
        );
      }
    },
    {
      title: "OS",
      dataIndex: "os",
      width: "10%",
      filterMultiple: true,
      filters: [
        {
          text: "Windows",
          value: "win"
        },
        {
          text: "Mac",
          value: "mac"
        },
        {
          text: "Linux",
          value: "linux"
        }
      ],
      onFilter: (value, record) => record.os === value,
      render: (record) => {
        return (
          <div>
            <div>{record === "win" ? "Windows" : (record === "mac" ? "Mac" : "Linux")}</div>
          </div>
        );
      }
    },
    {
      title: "App Version",
      dataIndex: "appVersion",
      width: "10%",
      sorter: {
        compare: (a, b) => a.appVersion.localeCompare(b.appVersion),
      },
      render: (record) => {
        return (
          <div>
            <div>{record}</div>
          </div>
        );
      }
    },
    {
      title: "App Using",
      dataIndex: "appUsings",
      width: "15%",
      render: (record) => {
        return (
          <div>
            <div>{record.join(", ")}</div>
          </div>
        );
      }
    }
  ];

  const config = useMemo(() => ({
    loading: loadingDailyStat,
    data: chartData,
    xField: 'date',
    yField: 'uniqueUsers',
    yAxis: {
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    tooltip: {
      showTitle: true,
      title: (value) => {
        if (chartType === "Weekly") {
          const date = value.split("W");

          if (date.length === 2) {
            const fromDate = moment.utc().day("Monday").isoWeek(date[1]).startOf("isoWeeks");
            const toDate = moment(fromDate).clone().endOf("isoWeeks");

            return `${value} \n\n (${fromDate.format("YYYY-MM-DD")} -> ${toDate.format("YYYY-MM-DD")})`;
          } else {
            return value;
          }
        } else {
          return value;
        }
      },
      customContent: (title, items) => {
        if (!items || items.length === 0) return null;

        const customText = 'Users'; // Text thay thế cho 'uniqueUsers'
        const value = items[0]?.value || 0; // Lấy giá trị từ điểm dữ liệu

        return `<div style="padding: 8px">
                <div style="margin-bottom: 4px; font-weight: bold;">${title}</div>
                <div>${customText}: ${value}</div>
              </div>`;
      },

    },
  }), [loadingDailyStat, chartData, chartType]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      let name = payload[0].name;
      if (name === "Others") {
        name = "Others";
      }

      return (
        <div className="bg-white border border-[#ddd] p-3 rounded-lg text-sm custom-tooltip">
          <p className="label">
            {`${name}: ${convertToDuration(payload[0].value)}`}
          </p>
        </div>
      );
    }

    return null;
  };

  const RenderPieChart = useCallback(({ pieData, loading }) => {
    return (
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <div>
          {loading ?
            <>
              <ResponsiveContainer className="!h-[400px] block pie-chart-large">
                <PieChart>
                  <Legend
                    align="center"
                    verticalAlign="bottom"
                  />
                  <Pie
                    data={[{
                      type: "",
                      duration: 1
                    }]}
                    fill="#eee"
                    dataKey="duration"
                    nameKey="type"
                    labelLine={false}
                    cx="50%"
                    cy="50%"
                    innerRadius={40}
                    legendType="none"
                    startAngle={90}
                    endAngle={-270}
                    isAnimationActive={false}
                  >
                    <Cell fill="#eee" className="animate-pulse" />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </>
            :
            <>
              {pieData.length > 0 ? (
                <>
                  <ResponsiveContainer className="!h-[400px] block pie-chart-large"                  >
                    <PieChart>
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        formatter={(value) => {
                          let valueRender = value;
                          if (valueRender === "Others") {
                            valueRender = "Others";
                          }

                          return <span style={{ color: "#000", fontSize: "14px" }}>{valueRender}</span>;
                        }}
                      />
                      <Pie
                        data={pieData}
                        fill="#8884d8"
                        dataKey="duration"
                        nameKey="type"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        cx="50%"
                        cy="50%"
                        innerRadius={40}
                        legendType="circle"
                        startAngle={90}
                        endAngle={-270}
                      >
                        {pieData.map((entry, index) => {
                          return (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          );
                        })}
                      </Pie>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  </ResponsiveContainer>
                </>
              ) : (
                <>
                  <ResponsiveContainer className="!h-[400px] block pie-chart-large"                  >
                    <PieChart>
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        formatter={(value) => {
                          let valueRender = value;
                          if (valueRender === "Others") {
                            valueRender = "Others";
                          }

                          return <span style={{ color: "#000", fontSize: "14px" }}>{valueRender}</span>;
                        }}
                        style={{ display: "inline-flex", alignItems: "center" }}
                      />
                      <Pie
                        data={[{
                          type: "No data",
                          duration: 1
                        }]}
                        fill="#eee"
                        dataKey="duration"
                        nameKey="type"
                        labelLine={false}
                        cx="50%"
                        cy="50%"
                        innerRadius={40}
                        legendType="circle"
                        startAngle={90}
                        endAngle={-270}
                        isAnimationActive={false}
                      >
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </>
              )
              }
            </>
          }
        </div>
      </Col>
    );
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const [result, result2] = await Promise.all([
          versionList(),
          getAllUsers(),
        ]);

        const newVersions = _.uniq(result.map(item => {
          return item.version;
        }));

        setVersions(newVersions);
        setUsers(result2.data);
      } catch (error) {
        console.log("error:", error);
      }
    })();
  }, []);

  const handleChartData = (data) => {
    let oldData = Object.keys(data).map(item => {
      return {
        type: item === "unknown" ? "Others" : item,
        hours: _.round(data[item] / 3600000, 1),
        duration: _.round(data[item], 1)
      };
    }).filter(pie => pie.duration > 0).sort((a, b) => b.duration - a.duration);

    let newData = [];
    const maxLength = 6;

    // Nếu tìm thấy dùng CrystalSound MVO thì đưa vào Other
    const crystalsoundIndex = oldData.findIndex(item => item.type === "CrystalSound MVO.exe");

    if (crystalsoundIndex !== -1) {
      const othersIndex = oldData.findIndex(item => item.type === "Others");
      if (othersIndex !== -1) {
        const newDuration = oldData[othersIndex].duration + oldData[crystalsoundIndex].duration;
        oldData[othersIndex] = {
          ...oldData[othersIndex],
          type: "Others",
          duration: _.round(newDuration, 1),
          hours: _.round(newDuration / 3600000, 1)
        };
        oldData = oldData.filter(item => item.type !== "CrystalSound MVO.exe");
      } else {
        oldData[crystalsoundIndex] = {
          ...oldData[crystalsoundIndex],
          type: "Others"
        };
      }
    }

    if (oldData.length > maxLength) {
      newData = oldData.slice(0, maxLength);

      const dataSlice = oldData.slice(maxLength);
      const othersIndex = oldData.findIndex(item => item.type === "Others");

      let totalDuration = 0;
      dataSlice.length > 0 && dataSlice.forEach(item => {
        totalDuration += item.duration;
      });

      // Khi data tồn tại Others
      if (othersIndex !== -1) {
        newData[othersIndex] = {
          ...newData[othersIndex],
          duration: _.round((newData[othersIndex].duration + totalDuration), 1),
          hours: _.round((newData[othersIndex].duration + totalDuration) / 3600000, 1)
        };
      } else {
        // if (newData[maxLength - 1].duration < totalDuration) {
        newData[maxLength - 1] = {
          type: "Others",
          duration: _.round((newData[maxLength - 1].duration + totalDuration), 1),
          hours: _.round((newData[maxLength - 1].duration + totalDuration) / 3600000, 1)
        };
        // }
      }
    } else {
      newData = [...oldData];
    }

    newData = newData.sort((a, b) => b.duration - a.duration);
    const findIndex = newData.findIndex(item => item.type === "Others");

    if (findIndex !== -1) {
      const tempData2 = newData.filter(item => item.type !== "Others");
      newData = [...tempData2, newData[findIndex]];
    }

    let colorIndex = 0;
    newData = newData.map(item => {
      if (item.type === "Others") {
        return {
          ...item,
          color: "#f4f4f4"
        };
      } else {
        return {
          ...item,
          color: COLORS[colorIndex++]
        };
      }
    });

    return newData;
  };

  const getStatData = async ({
    fromDateParam = fromDate,
    toDateParam = toDate,
    emailParam = email,
    appVersionParam = appVersion,
    osParam = os
  }) => {
    try {
      setLoadingStat(true);

      if (!fromDateParam || !toDateParam) {
        setLoadingStat(false);
        return;
      }

      const payload = {
        fromDate: fromDateParam.toISOString(),
        toDate: toDateParam.toISOString(),
        email: emailParam,
        appVersion: appVersionParam && appVersion !== "all" ? appVersion : "",
        os: osParam && osParam !== "all" ? osParam : "",
      };

      const [result1, result2, result3] = await Promise.all([
        getAdminAggregatedUsagesByOS(payload),
        getAdminAggregatedUsagesByAppVersion(payload),
        getAdminAggregatedUsagesByAppUsing(payload),
      ]);

      setAppOsData(handleChartData(result1.data));
      setAppVersionData(handleChartData(result2.data));
      setAppUsingsData(handleChartData(result3.data));
    } catch (error) {
      console.log("error when load more usage:", error);
      setAppOsData([]);
      setAppVersionData([]);
      setAppUsingsData([]);
    } finally {
      setLoadingStat(false);
    }
  };

  const searchUsageList = async ({
    fromDateParam = fromDate,
    toDateParam = toDate,
    emailParam = email,
    appVersionParam = appVersion,
    osParam = os,
    lastVisibleIdParam = lastVisibleId,
    forceUpdate = false
  }) => {
    try {
      setLoadingUsages(true);

      if (!fromDateParam || !toDateParam) {
        setLoadingUsages(false);
        return;
      }

      const payload = {
        fromDate: fromDateParam.toISOString(),
        toDate: toDateParam.toISOString(),
        limit: 1000,
        email: emailParam,
        appVersion: appVersionParam && appVersionParam !== "all" ? appVersionParam : "",
        os: osParam && osParam !== "all" ? osParam : "",
        lastVisibleId: forceUpdate ? "" : lastVisibleIdParam
      };

      const result = await getAdminAggregatedUsages(payload);
      const { usages, lastVisibleId: lastVisibleIdRes, total, totalPage } = result.data;

      console.log("result:", result);

      const newUsagesList = forceUpdate ? [...usages] : [...usagesList, ...usages];

      setUsagesList(newUsagesList);
      setTotal(total);
      setLastVisibleId(lastVisibleIdRes);
    } catch (error) {
      console.log("error when search usages list:", error);

      if (forceUpdate) {
        setUsagesList([]);
        setTotal(0);
        setLastVisibleId("");
      }
    } finally {
      setLoadingUsages(false);
    }
  };

  const handleChangeChartType = async ({
    fromDateParam = fromDate,
    toDateParam = toDate,
    emailParam = email,
    appVersionParam = appVersion,
    osParam = os,
    chartTypeParam = chartType
  }) => {
    try {
      setLoadingDailyStat(true);

      if (!fromDateParam || !toDateParam) {
        setLoadingDailyStat(false);
        return;
      }

      const payload = {
        fromDate: fromDateParam.toISOString(),
        toDate: toDateParam.toISOString(),
        email: emailParam,
        appVersion: appVersionParam && appVersionParam !== "all" ? appVersionParam : "",
        os: osParam && osParam !== "all" ? osParam : "",
        groupBy: chartTypeParam === "Weekly" ? "week" : chartTypeParam === "Monthly" ? "month" : "day",
      };

      const result = await getAdminDailyStats(payload);

      setUsageDailyStats(result.data);
      setChartData(result.data);
    } catch (error) {
      console.log("error when search usages list:", error);
      setUsageDailyStats([]);
      setChartData([]);
    } finally {
      setLoadingDailyStat(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        await Promise.allSettled([
          searchUsageList({}),
          getStatData({}),
          handleChangeChartType({})
        ]);
      }
    }

    fetchData();
  }, [user]);

  return (
    <Layout className="jobs-page" style={{ zIndex: 5 }}>
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Usages</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <RangePicker
                ranges={{
                  'Today': [moment(), moment()],
                  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'This Week': [moment().startOf('week'), moment().endOf('week')],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
                  'This Year': [moment().startOf('year'), moment().endOf('year')],
                }}
                onChange={onChange}
                defaultValue={[moment().clone().subtract(7, 'days'), moment()]}
                allowClear={false}
              />
              <Select
                placeholder="Version"
                style={{ width: 90, marginLeft: 16 }}
                value={appVersion}
                onChange={(value) => {
                  setAppVersion(value);
                }}
              >
                {versions.map((version) => {
                  return <Select.Option value={version} key={version}>{version}</Select.Option>;
                })}
                <Select.Option value="all">All</Select.Option>
              </Select>
              <Select
                placeholder="OS"
                style={{ width: 120, marginLeft: 16 }}
                value={os}
                onChange={(value) => {
                  setOs(value);
                }}
              >
                <Select.Option value="win">Win</Select.Option>
                <Select.Option value="mac">Mac</Select.Option>
                <Select.Option value="linux">Linux</Select.Option>
                <Select.Option value="all">All</Select.Option>
              </Select>
              <Input
                placeholder="Email"
                style={{ width: 120, marginLeft: 16 }}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
              <Button
                type="primary"
                loading={loadingUsages || loadingDailyStat || loadingStat}
                style={{ marginLeft: 24 }}
                icon={<SearchOutlined />}
                disabled={loadingUsages || loadingDailyStat || loadingStat}
                onClick={async () => {
                  await Promise.allSettled([
                    searchUsageList({
                      forceUpdate: true
                    }),
                    getStatData({}),
                    handleChangeChartType({})
                  ]);
                }}
              >
                Search
              </Button>


              <div style={{ float: "right", display: "flex", alignItems: "center", justifyContent: "center", gap: "24px" }}>
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    const templateData = usagesList.map(item => {
                      const userInfo = users.find(user => user.email === item?.email);
                      const timestamp = moment(item.timestamp * 1000).format("YYYY-MM-DD");

                      return {
                        "Last Usage": timestamp,
                        "Email": item?.email,
                        "Duration": item?.duration,
                        "Duration Value (s)": item?.duration / 1000,
                        "Source": userInfo?.source ? capitalizeFirstLetter(userInfo?.source) : "",
                        "Role": userInfo?.role ? capitalizeFirstLetter(userInfo?.role) : "",
                        "GiveAway": userInfo?.isGiveAway ? "X" : "",
                      };
                    });

                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    const fileExtension = '.xlsx';
                    const ws = XLSX.utils.json_to_sheet(templateData);
                    const wb = { Sheets: { Data: ws }, SheetNames: ['Data'] };

                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const dataFile = new Blob([excelBuffer], { type: fileType });
                    const fileName = `usages_${moment().format("MMMM_Do_YYYY_h_mm").trim()}`;
                    FileSaver.saveAs(dataFile, fileName + fileExtension);
                  }}
                >
                  Download
                </Button>
              </div>
            </Col>
          </Row>
        </Card>

        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col span={24}>
              <Card style={{ display: 'block' }}>
                <div style={{ marginBottom: '20px' }}>
                  <Radio.Group
                    value={chartType}
                    onChange={(e) => {
                      setChartType(e.target.value);

                      handleChangeChartType({
                        chartTypeParam: e.target.value
                      });
                    }}
                    disabled={loadingDailyStat}
                  >
                    <Radio.Button style={{ marginRight: '8px' }} value="Daily">Daily</Radio.Button>
                    <Radio.Button style={{ marginRight: '8px' }} value="Weekly">Weekly</Radio.Button>
                    <Radio.Button style={{ marginRight: '16px' }} value="Monthly">Monthly</Radio.Button>
                  </Radio.Group>
                </div>
                <Line {...config} />
              </Card>

              <Row gutter={24} type="flex" style={{ marginTop: "32px" }}>
                <RenderPieChart loading={loadingStat} pieData={appUsingsData} />
                <RenderPieChart loading={loadingStat} pieData={appOsData} />
                <RenderPieChart loading={loadingStat} pieData={appVersionData} />
              </Row>
            </Col>
          </Row>
        </Card>

        <Table
          loading={loadingUsages}
          bordered
          columns={columns}
          dataSource={usagesList}
          pagination={{
            defaultPageSize: 20
          }}
          rowKey={(row) => row.key}
        />

        {lastVisibleId && (
          <Row style={{ margin: "16px 0px" }}>
            <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Button
                type="primary"
                loading={loadingUsages}
                disabled={total === usagesList.length}
                onClick={async () => {
                  await searchUsageList({});
                }}
              >
                Load more
              </Button>
            </Col>
          </Row>
        )}
      </Layout>
    </Layout>
  )
};

export default Usage;
