import React from 'react';
import { Form, Input, Col, Row, Button, Checkbox, message } from 'antd';
import { tagUpdate } from 'apis/tags';

const TagEditForm = ({ tag, onCancel, onCallback }) => {
  const [form] = Form.useForm();

  return (
    <Form
        form={form}
        layout="vertical"
        name="tagUpdateForm"
        onFinish={async values => {
          let item = {
            tagId: tag?.tagId,
          }

          if (values?.name) {
            item.name = values?.name;
          }

          if (values?.body) {
            item.body = values?.body;
          }

          if (values?.isPopular) {
            item.isPopular = values?.isPopular;
          } else {
            item.isPopular = false;
          }

          if (values?.isActive) {
            item.isActive = values?.isActive;
          } else {
            item.isActive = false;
          }

          let result = await tagUpdate(item);
          if (result.status === 'success') {
            onCancel()
            onCallback()
          } else {
            message.error(result?.error?.message);
          }
        }}
        initialValues={tag}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="name" 
              label="Tên Tag" 
              rules={[
                {
                  required: true,
                  message: 'Please input the Name!'
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="body" 
              label="Description" 
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item name="isPopular" label="Đặt nổi bật" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
          <Col lg={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item name="isActive" label="Active" valuePropName="checked">
              <Checkbox>Active</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            <Col className="gutter-row" span={12} style={{ textAlign: 'left', paddingLeft: 20 }}>
              {tag.tagId}
            </Col>
            <Col className="gutter-row" span={12} style={{textAlign: 'right'}}>
              <Button type="clear" onClick={onCancel}>
                  Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                  Save
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
  );
};

export default TagEditForm;