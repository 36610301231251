/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';

import { Layout, Button, Table, DatePicker, Row, Col, Input, Card, Space, message } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import { enterpriseList } from 'apis/enterprises';
import { getAdminUsagesResellerDashboard } from 'apis/usage';

import _ from 'lodash';
import moment from 'moment';

import './styles.less';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import useToken from 'hooks/useToken';
import useAuth from 'hooks/useAuth';

const { RangePicker } = DatePicker;

const UsageReseller = () => {
  const { token } = useToken();
  const { user: userProfile } = useAuth();
  const [loading, setLoading] = useState(false);

  const searchInputEl = useRef(null);
  const [usagesFilter, setUsagesFilter] = useState([]);
  const [emailResellers, setEmailResellers] = useState([]);

  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');

  const [fromDate, setFromDate] = useState(moment().subtract(7, 'days'));
  const [toDate, setToDate] = useState(moment());

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <div>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? text[dataIndex].toString() : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  function onChange(dates, dateStrings) {
    if (!dates) {
      setFromDate(null);
      setToDate(null);
    }

    if (dates && dates[0]) {
      setFromDate(dates[0])
    } else {
      setFromDate()
    }

    if (dates && dates[1]) {
      setToDate(dates[1])
    } else {
      setToDate()
    }
  }

  const convertToDuration = (value) => {
    let diffInMilliSeconds = Math.round(value / 1000);

    // calculate days
    const days = Math.floor(diffInMilliSeconds / (3600 * 24));
    diffInMilliSeconds -= days * 3600 * 24;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600);
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate minutes
    const seconds = diffInMilliSeconds;

    if (days > 0) {
      return `${days < 10 ? "0" : ""}${days}:${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""
        }${seconds}`;
    }
    return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""
      }${seconds}`;
  };

  const columns = [
    {
      title: "Last usage",
      dataIndex: "lastUsage",
      width: "20%",
      sorter: {
        compare: (a, b) => {
          return moment(a.lastUsage) - moment(b.lastUsage);
        },
      },
      defaultSortOrder: "descend",
      render: (record) => {
        return (
          <div>
            <div>{moment(record).format("YYYY-MM-DD HH:mm")}</div>
          </div>
        );
      }
    },
    {
      title: "Email",
      width: "30%",
      ...getColumnSearchProps("email")
    },
    {
      title: "Total Duration",
      dataIndex: "totalDuration",
      width: "25%",
      sorter: {
        compare: (a, b) => a.duration.localeCompare(b.duration),
      },
      render: (record) => {
        return (
          <div>
            <div>{record}</div>
          </div>
        );
      }
    },
    {
      width: "20%",
      key: "action",
      render: (record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {record?.details.length > 0 &&
              <Button
                type="link"
                icon={<DownloadOutlined />}
                onClick={() => {
                  const templateData = record?.details?.map(item => {
                    return {
                      "Created At": item?.createdAt,
                      "Duration": item?.duration,
                      "Start Time": item?.startTime,
                      "End Time": item?.endTime,
                      "OS": item?.os,
                      "App Version": item?.appVersion,
                      "Model Version": item?.modelVersion,
                      "Current Mic": item?.curMic,
                      "Current Speaker": item?.curSpeaker,
                      "App Using": item?.appUsings
                    };
                  });

                  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                  const fileExtension = '.xlsx';
                  const ws = XLSX.utils.json_to_sheet(templateData);
                  const wb = { Sheets: { Data: ws }, SheetNames: ['Data'] };

                  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                  const data = new Blob([excelBuffer], { type: fileType });
                  const fileName = `usages_${record?.email}_${moment().format("MMMM_Do_YYYY_h_mm").trim()}`;
                  FileSaver.saveAs(data, fileName + fileExtension);
                }}
              >
                Download
              </Button>
            }
          </div>
        );
      }
    },
  ];

  const columnsMember = [
    {
      title: "Duration",
      dataIndex: "duration",
      width: "5%",
      sorter: {
        compare: (a, b) => a.duration.localeCompare(b.duration),
      },
      render: (record) => {
        return (
          <div>
            <div>{record}</div>
          </div>
        );
      }
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      width: "5%",
      sorter: {
        compare: (a, b) => { return moment(a.startTime) - moment(b.startTime) },
      },
      render: (record) => {
        return (
          <div>
            <div>{moment(record).format("YYYY-MM-DD HH:mm:ss")}</div>
          </div>
        );
      }
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      width: "5%",
      sorter: {
        compare: (a, b) => { return moment(a.endTime) - moment(b.endTime) },
      },
      render: (record) => {
        return (
          <div>
            <div>{moment(record).format("YYYY-MM-DD HH:mm:ss")}</div>
          </div>
        );
      }
    },
    {
      title: "OS",
      dataIndex: "os",
      width: "5%",
      filterMultiple: true,
      filters: [
        {
          text: "Windows",
          value: "win"
        },
        {
          text: "Mac",
          value: "mac"
        },
        {
          text: "Linux",
          value: "linux"
        }
      ],
      onFilter: (value, record) => record.os === value,
      render: (record) => {
        return (
          <div>
            <div>{record === "win" ? "Windows" : (record === "mac" ? "Mac" : "Linux")}</div>
          </div>
        );
      }
    },
    {
      title: "App Version",
      dataIndex: "appVersion",
      width: "5%",
      sorter: {
        compare: (a, b) => a.appVersion.localeCompare(b.appVersion),
      },
      render: (record) => {
        return (
          <div>
            <div>{record}</div>
          </div>
        );
      }
    },
    {
      title: "Model Version",
      dataIndex: "modelVersion",
      width: "5%",
      sorter: {
        compare: (a, b) => a.modelVersion.localeCompare(b.modelVersion),
      },
      render: (record) => {
        return (
          <div>
            <div>{record}</div>
          </div>
        );
      }
    },
    {
      title: "App Using",
      dataIndex: "appUsings",
      width: "8%",
      render: (record) => {
        return (
          <div>
            <div>{record}</div>
          </div>
        );
      }
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <Table
        loading={loading}
        bordered
        columns={columnsMember}
        dataSource={record.details}
        pagination={false}
      />
    )
  };

  useEffect(() => {
    (async () => {
      try {
        if (userProfile) {
          //get emails of reseller
          const [reseller] = await Promise.all([
            enterpriseList({
              createdBy: userProfile?.data?.uid
            }, token)
          ]);

          // fetch data
          setLoading(true)
          let payload = {
            fromDate: fromDate.format('YYYY-MM-DD'),
            toDate: toDate.format('YYYY-MM-DD'),
          };

          setEmailResellers(reseller.map(x => x.email));
          console.log("resultEmail:", reseller.map(x => x.email));

          const result = await getAdminUsagesResellerDashboard({
            ...payload,
            emails: reseller.map(x => x.email),
          });

          console.log("result:", result);

          setUsagesFilter(result.data);
          setLoading(false);
        }
      } catch (e) {
        message.error("Get Usages Failed");
        setEmailResellers([]);
        setUsagesFilter([]);
        setLoading(false);
      }
    })();
  }, [userProfile]);

  useEffect(() => {
    const dataGroupBy = _.groupBy(usagesFilter, function (item) {
      return item?.email
    });

    const data = [];

    for (const [key, value] of Object.entries(dataGroupBy)) {
      let totalDurationValue = 0;
      let details = [];

      value.forEach(usage => {
        const { appSettings, ...rest } = usage;
        let appUsings = [];
        let curMic = "";
        let curSpeaker = "";

        if (rest.type === "recording") {
          appUsings = ["Recording Magic"];
        } else {
          if (appSettings) {
            const micAppsUsings = appSettings?.micAppsUsing ? _.uniq(appSettings?.micAppsUsing.split(",")) : [];
            const speakerAppsUsings = appSettings?.speakerAppsUsing ? _.uniq(appSettings?.speakerAppsUsing.split(",")) : [];
            appUsings = _.union(micAppsUsings, speakerAppsUsings);

            if (appUsings.length <= 0) {
              appUsings = ["Unknown"];
            }
          } else {
            appUsings = ["Unknown"];
          }
        }

        curMic = appSettings?.curMic;
        curSpeaker = appSettings?.curSpeaker;
        totalDurationValue += usage.durationValue;

        details.push({
          ...rest,
          appSettings,
          appUsings: appUsings.join(", "),
          curMic,
          curSpeaker
        });
      });

      const item = {
        email: key,
        totalDurationValue,
        totalDuration: convertToDuration(totalDurationValue),
        lastUsage: value[0].createdAt,
        details
      };

      data.push(item);
    }

    setData(data);
  }, [usagesFilter]);

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Usages Resellers</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <RangePicker
                ranges={{
                  'Today': [moment(), moment()],
                  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'This Week': [moment().startOf('week'), moment().endOf('week')],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
                  'This Year': [moment().startOf('year'), moment().endOf('year')],
                }}
                onChange={onChange}
                defaultValue={[moment().clone().subtract(7, 'days'), moment()]}
                allowClear={false}
              />
              <Button
                type="primary"
                loading={loading}
                style={{ marginLeft: 24 }}
                icon={<SearchOutlined />}
                disabled={!fromDate || !toDate}
                onClick={async () => {
                  try {
                    setLoading(true);

                    if (!fromDate || !toDate) {
                      setLoading(false);
                      return;
                    }

                    let payload = {
                      fromDate: fromDate.format('YYYY-MM-DD'),
                      toDate: toDate.format('YYYY-MM-DD'),
                    };

                    
                    console.log("~~~ resultEmail:", emailResellers);
                    const result = await getAdminUsagesResellerDashboard({
                      ...payload,
                      emails: emailResellers,
                    });

                    console.log("~~~++++ result:", result);
                    setUsagesFilter(result.data);
                    setLoading(false);
                  } catch (e) {
                    message.error("Get Usages Failed");
                    setEmailResellers([]);
                    setUsagesFilter([]);
                    setLoading(false);
                  }
                }}
              >
                Search
              </Button>

              {/* <div style={{ float: "right", display: "flex", alignItems: "center", justifyContent: "center", gap: "24px" }}>
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    const templateData = data.map(item => {
                      return {
                        "Last Usage": item?.lastUsage,
                        "Email": item?.email,
                        "Total Duration": item?.totalDuration,
                      };
                    });

                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    const fileExtension = '.xlsx';
                    const ws = XLSX.utils.json_to_sheet(templateData);
                    const wb = { Sheets: { Data: ws }, SheetNames: ['Data'] };

                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const dataFile = new Blob([excelBuffer], { type: fileType });
                    const fileName = `usages_${moment().format("MMMM_Do_YYYY_h_mm").trim()}`;
                    FileSaver.saveAs(dataFile, fileName + fileExtension);
                  }}
                >
                  Download
                </Button>
              </div> */}
            </Col>
          </Row>
        </Card>

        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 20
          }}
          rowKey={(row) => row.email}
          expandable={{
            expandedRowRender: row => expandedRowRender(row)
          }}
        />
      </Layout>
    </Layout>
  )
};

export default UsageReseller;
