import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Form, Input, Col, Row, Button, Checkbox, Select, InputNumber, message } from 'antd';
import { categoryUpdate } from 'apis/categories';

import ReactQuill, { Quill } from 'react-quill';
import '../../../../../node_modules/react-quill/dist/quill.snow.css';

import { QuillToolbarContainer, QuillFormats } from 'utils/quill';
import { uploadFile } from 'apis/other';

import _ from 'lodash';

const { Option } = Select;

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const CategoryEditForm = ({ token, category, onCancel, categories, onCallback }) => {
  const [form] = Form.useForm();
  const [body, setBody] = useState('');
  const quillRef = useRef(null);

  const imageHandler = () => {
    const quillEditor = quillRef.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input.files[0]

      uploadFile(file, token)
        .then(result => {
          const { url } = result;

          const range = quillEditor.getSelection();
          quillEditor.insertEmbed(range.index, 'image', url)
        })
        .catch(error => console.log(error));
    }
  }

  const modules = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
      handlers: {
        'image': imageHandler
      }
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  useEffect(() => {
    setBody(_.get(category, 'body', ''));
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      name="categoryUpdateForm"
      onFinish={async values => {
        let item = {
          categoryId: category?.categoryId
        };
        if (values?.name) {
          item.name = values?.name;
        }

        if (values?.categoryParentId) {
          item.categoryParentId = values?.categoryParentId;
        } else {
          item.categoryParentId = "";
        }

        if (values?.no) {
          item.no = values?.no;
        }

        if (body) {
          item.body = body;
        } else {
          item.body = '';
        }

        if (values?.isPopular) {
          item.isPopular = values?.isPopular;
        } else {
          item.isPopular = false;
        }

        if (values?.isActive) {
          item.isActive = values?.isActive;
        } else {
          item.isActive = false;
        }

        let result = await categoryUpdate(item);
        if (result.status === 'success') {
          onCancel()
          onCallback()
        } else {
          message.error(result?.error?.message);
        }
      }}
      initialValues={category}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 9 }} xs={{ span: 24 }}>
          <Form.Item
            name="name"
            label="Category Name"
            rules={[
              {
                required: true,
                message: 'Please input the Name!'
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={{ span: 9 }} xs={{ span: 24 }}>
          <Form.Item
            name="categoryParentId"
            label="Category Parent"
          >
            <Select
              defaultValue={category.categoryId}
              showSearch
              placeholder="Category Parent"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={''}></Option>
              {categories.map((item) => {
                return (
                  <Option value={item.categoryId}>{item.name}</Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 3 }} xs={{ span: 24 }}>
          <Form.Item
            name="no"
            label="No."
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập Số thứ tự!'
              }
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item label="Mô tả">
            <ReactQuill
              ref={quillRef}
              modules={modules}
              formats={QuillFormats}
              value={body}
              placeholder=""
              theme="snow"
              className="rich-text-item"
              onChange={(value) => setBody(value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} type="flex">
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <Form.Item name="isPopular" label="Đặt nổi bật" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Col>
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <Form.Item name="isActive" label="Active" valuePropName="checked">
            <Checkbox>Active</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={12} style={{ textAlign: 'left', paddingLeft: 20 }}>
            {category.categoryId}
          </Col>
          <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
            <Button type="clear" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default CategoryEditForm;