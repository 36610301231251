import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Button, Card, Col, Radio, Spin } from 'antd';
import ReactApexChart from "react-apexcharts";
import { useUserHistory } from 'hooks/use-user-history';
import { useUsageChartData } from 'hooks/use-user-chart-data';
import { DownloadOutlined } from '@ant-design/icons';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';

const MAX_USERS_TO_PROCESS = 1000;

const transformDataV2 = (input) => {
  const sortedKeys = Object.keys(input).sort((a, b) => new Date(a) - new Date(b));
  const result = [];

  for (const date of sortedKeys) {
    const { useUsers, unuseUsers, totalUsers } = input[date]; // Lấy dữ liệu từ đối tượng theo date

    Array.prototype.push.apply(result, [
      {
        date: date,
        useUsers,
        unuseUsers,
        totalUsers,
      },
    ]);
  }

  return result;
};

const transformDataByTypeV2 = (chartData, dateFormat) => {
  const groupedData = {};

  for (const item of chartData) {
    const weekKey = moment(item.date).format(dateFormat);

    if (!groupedData[weekKey]) {
      groupedData[weekKey] = {
        useUsers: 0,
        unuseUsers: 0,
        totalUsers: 0,
      };
    }

    groupedData[weekKey].useUsers += item.useUsers;
    groupedData[weekKey].unuseUsers += item.unuseUsers;
    groupedData[weekKey].totalUsers += item.totalUsers;
  }

  // Chuyển đổi groupedData thành mảng kết quả
  const result = [];

  for (const week in groupedData) {
    Array.prototype.push.apply(result, [
      {
        date: week,
        useUsers: groupedData[week].useUsers,
        unuseUsers: groupedData[week].unuseUsers,
        totalUsers: groupedData[week].totalUsers,
      },
    ]);
  }

  return result;
};

const UserUsageChart = ({ usersHistoryFilter = [], fromDate, toDate, ...props }) => {
  const {
    usersHistory,
    initialLoading,
    loading: loadingUser
  } = useUserHistory();
  const {
    daysData, setDaysData, chartData, setChartData, lastUserId, setLastUserId, loading, setLoading, onReset
  } = useUsageChartData();

  const [chartType, setChartType] = useState("Daily");
  const [chartDataFilter, setChartDataFilter] = useState([]);
  const lastUserIdRef = useRef(null);
  const loadingRef = useRef(false);

  const handleReset = () => {
    onReset();
  };

  useEffect(() => {
    if (!loading) {
      loadingRef.current = true;

      try {
        setLoading(true);

        if (usersHistoryFilter.length > 0) {
          const type = "day";
          const dateFormat = "YYYY-MM-DD";

          let days = {};
          let date = moment(toDate);

          while (moment(fromDate).isBefore(moment(date), type)) {
            days[date.format(dateFormat)] = { useUsers: 0, unuseUsers: 0, totalUsers: 0 };
            date = date.subtract(1, type);
          }

          days[date.format(dateFormat)] = { useUsers: 0, unuseUsers: 0, totalUsers: 0 };

          let newUsersHistoryIndex = -1;

          if (lastUserId) {
            newUsersHistoryIndex = usersHistoryFilter.findIndex(user => {
              if (user.userId === lastUserId) {
                return true;
              } else {
                return false;
              }
            });
          }

          const newUsersHistory = usersHistoryFilter.slice(newUsersHistoryIndex + 1, newUsersHistoryIndex + 1 + MAX_USERS_TO_PROCESS);

          if (newUsersHistory.length > 0) {
            newUsersHistory.forEach(user => {
              const date = moment(user.createdAt).format(dateFormat);
              const isInvalid = moment(user.createdAt).isBefore(fromDate.clone().startOf("day")) ||
                moment(user.createdAt).isAfter(toDate.clone().endOf("day"));

              if (!isInvalid && date in days) {
                if (user.isUsages === true) {
                  days[date].useUsers++;
                } else {
                  days[date].unuseUsers++;
                }

                days[date].totalUsers++;
              }
            });

            lastUserIdRef.current = newUsersHistory[newUsersHistory.length - 1]?.userId;
            setLastUserId(newUsersHistory[newUsersHistory.length - 1]?.userId);

            const mergedData = { ...daysData };

            for (const key in days) {
              if (mergedData[key]) {
                // Nếu key đã tồn tại trong mergedData, cộng dồn các giá trị
                mergedData[key].useUsers += days[key].useUsers;
                mergedData[key].unuseUsers += days[key].unuseUsers;
                mergedData[key].totalUsers += days[key].totalUsers;
              } else {
                // Nếu key không tồn tại, thêm mới vào mergedData
                mergedData[key] = days[key];
              }
            }

            setDaysData(mergedData); // Lưu dữ liệu đã tính sẵn
          }
        } else {
          handleReset();
        }
      } catch (error) {
        console.log("error UserPaymentChart:", error);
      } finally {
        setLoading(false);
        loadingRef.current = false;
      }
    }
  }, [usersHistoryFilter, lastUserId, loading]);

  useEffect(() => {
    const transformedData = transformDataV2(daysData);
    setChartData(transformedData);
  }, [daysData, setChartData]);

  useEffect(() => {
    if (chartType === "Daily") {
      setChartDataFilter(chartData);
    } else {
      const type = chartType === "Monthly" ? 'month' : chartType === "Weekly" ? 'week' : 'day';
      const dateFormat = type === 'month' ? "YYYY-MM" : type === 'day' ? "YYYY-MM-DD" : "YYYY-[W]WW";
      const transformedData = transformDataByTypeV2(chartData, dateFormat);
      setChartDataFilter(transformedData);
    }
  }, [chartData, chartType]);

  const options = useMemo(() => ({
    chart: {
      type: 'line',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2,
      curve: 'smooth'
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
      }
    },
    xaxis: {
      categories: chartDataFilter?.map(item => item.date),
      tickAmount: 24,
    },
    colors: ['#5B8FF9', '#5ad8a6', '#FF76A1'],
    tooltip: {
      enabled: !loadingUser,
      x: {
        show: true,
        formatter: function (val, opts) {
          const value = opts.w.globals.categoryLabels[opts.dataPointIndex];

          if (chartType === "Weekly") {
            const date = value.split("W");

            if (date.length === 2) {
              const fromDate = moment().day("Monday").isoWeek(date[1]).format("YYYY-MM-DD");
              const toDate = moment().day("Sunday").isoWeek(date[1]).format("YYYY-MM-DD");

              return `${value} \n\n (${fromDate} -> ${toDate})`;
            } else {
              return value;
            }
          } else {
            return value;
          }
        }
      },
      y: [
        {
          title: {
            formatter: function (val) {
              return val;
            }
          }
        },
        {
          title: {
            formatter: function (val) {
              return val;
            }
          }
        },
        {
          title: {
            formatter: function (val) {
              return val;
            }
          }
        }
      ]
    },
    grid: {
      borderColor: '#f1f1f1',
    },
    noData: {
      text: "There's no data",
      align: 'center',
      verticalAlign: 'middle'
    }
  }), [chartDataFilter, chartType, loadingUser]);

  const series = useMemo(() =>
    [
      {
        name: "Used",
        data: chartDataFilter?.map(item => item.useUsers)
      },
      {
        name: "Unused",
        data: chartDataFilter?.map(item => item.unuseUsers)
      },
      {
        name: "Total",
        data: chartDataFilter?.map(item => item.totalUsers)
      },
    ], [chartDataFilter]);

  return (
    <Col span={24}>
      <Card style={{ display: 'block' }}>
        <div style={{ marginBottom: '20px' }}>
          <Button
            style={{ float: 'right' }}
            type={'link'}
            icon={<DownloadOutlined />}
            onClick={() => {
              const templateData = chartDataFilter.map(item => {
                return {
                  'Date': item?.date,
                  'Unused users': item?.unuseUsers,
                  'Used users': item?.useUsers,
                  'Total users': item?.totalUsers,
                };
              });

              const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
              const fileExtension = '.xlsx';
              const ws = XLSX.utils.json_to_sheet(templateData);
              const wb = { Sheets: { Data: ws }, SheetNames: ['Data'] };

              const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
              const data = new Blob([excelBuffer], { type: fileType });
              const fileName = `users_usage_${moment().format("MMMM_Do_YYYY_h_mm").trim()}`;
              FileSaver.saveAs(data, fileName + fileExtension);
            }}
          >
            Download
          </Button>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <Radio.Group value={chartType} onChange={(e) => setChartType(e.target.value)}>
            <Radio.Button style={{ marginRight: '8px' }} value="Daily">Daily</Radio.Button>
            <Radio.Button style={{ marginRight: '8px' }} value="Weekly">Weekly</Radio.Button>
            <Radio.Button style={{ marginRight: '16px' }} value="Monthly">Monthly</Radio.Button>
          </Radio.Group>
        </div>

        {initialLoading ?
          <Spin tip="Loading" size="large">
            <div
              style={{
                width: "100%",
                height: "500px",
                display: "flex",
                alignContent: "center",
                justifyContent: "center"
              }}
            />
          </Spin>
          :
          <ReactApexChart
            options={options}
            series={series}
            height={500}
          />
        }
      </Card>
    </Col>
  );
};

export default UserUsageChart;