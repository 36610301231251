import React, {useState, useEffect, useRef} from 'react';
import './styles.less';

import Highlighter from 'react-highlight-words';
import { Layout, Button, Table, Card, Row, Col, Space, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { contactList } from 'apis/contacts';

import useToken from 'hooks/useToken';
import moment from 'moment';

const Contact = () => {
  const {token} = useToken();
  const [loading, setLoading] = useState(true)
  const searchInputEl = useRef(null);
  const [contacts, setContacts] = useState([]);
  const [contactsFilter, setContactsFilter] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div>
            <div>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? text[dataIndex].toString() : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  useEffect(() => {
    contactList()
    .then(result => {
      setContacts(result)
      setContactsFilter(result)
      setLoading(false)
    });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {
      title: 'Created At',
      align: 'center',
      dataIndex: 'createdAt',
      width: '8%',
      render: (text, record, index) => {
        return (
          `${moment(record.createdAt).format('YYYY-MM-DD hh:mm')}`
        );
      }
    },
    {
      title: 'Name',
      width: '10%',
      render: (record) => {
        return (
          <div>
            {record.firstName} {record.lastName}
          </div>
        )
      }
    },
    {
      title: 'Email',
      align: 'center',
      width: '8%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Message',
      width: '30%',
      render: (record) => {
        return (
          <div>
            {record.message}
          </div>
        )
      }
    },
  ];

  return (
    <Layout className="categories-page">
      <Layout style={{ padding: '0 24px 24px' }}>
        <Card style={{marginBottom: 18}}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
              <Col className="gutter-row" span={12}>
                <h2>Contacts</h2>
              </Col>
            </Row>
        </Card>
        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={contactsFilter}
          onChange={(pagination, filters, sorter) => {
            setCurrentPage(pagination.current);
          }}
          pagination={{
            total: contactsFilter.lenght,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            pageSize: 20,
            current: currentPage
          }}
        />
      </Layout>
    </Layout>
  )
};

export default Contact;