import { db } from 'utils/firebase';
import { Timestamp, collection, query, orderBy, where, getDocs } from "firebase/firestore";

export const appSumoList = async (payload) => {
  try {
    let q = query(collection(db, "appsumos"), orderBy('createdAt', 'desc'));

    //fromDate, toDate
    if (payload.fromDate) {
      let start = Timestamp.fromDate(new Date(payload.fromDate))
      q = query(q, where('createdAt', '>', start));
    }
    if (payload.toDate) {
      let end = Timestamp.fromDate(new Date(`${payload.toDate} 23:59:59`))
      q = query(q, where('createdAt', '<', end));
    }

    const querySnapshot = await getDocs(q);
    console.log('querySnapshot.size: ', querySnapshot?.size)

    let items = [];
    querySnapshot.forEach((doc) => {
      items.push({
        appsumoId: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
      });
    });
    return items;
  } catch (error) {
    console.log(error)
    return []
  }
};