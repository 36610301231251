import React, { useState, useEffect, useRef } from 'react';

import { Layout, Button, Table, DatePicker, Row, Col, Input, Card, Space, Tag, Statistic } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import useAuth from 'hooks/useAuth';
import {appSumoList} from 'apis/appsumo';
import moment from 'moment';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';

import './styles.less';

const { RangePicker } = DatePicker;

const AppSumos = () => {
  const {user} = useAuth();
  const [loading, setLoading] = useState([]);
  
  const searchInputEl = useRef(null);
  const [users, setUsers] = useState([]);

  const [usersFilter, setUsersFilter] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [fromDate, setFromDate] = useState(moment().subtract(3, 'months'));
  const [toDate, setToDate] = useState(moment());


  useEffect(() => {
    (async () => {
      try {
        //setLoading(true)
        if (user) {
          //fetch data
          setLoading(true)
          let payload = {};
          const [result] = await Promise.all([
            appSumoList(payload),
          ]);

          setUsers(result)
          setUsersFilter(result)
          setLoading(false)
        }
      } catch (e) {
        setLoading(false)
      }
    })();
  }, [user])

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div>
            <div>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? text[dataIndex].toString() : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  function onChange(dates, dateStrings) {
    if (!dates) {
      setFromDate(null);
      setToDate(null);
    }

    if (dates && dates[0]) {
      setFromDate(dates[0])
    }

    if (dates && dates[1]) {
      setToDate(dates[1])
    }
  }

  const columns = [
    {
      title: 'Action', 
      align: 'center',
      dataIndex: 'action',
      render: (record) => {
        if (record === 'activate' ) {
          return <Tag color="magenta">Activate</Tag>;
        } else if (record === 'enhance_tier') {
          return <Tag color="red">Upgrade</Tag>;
        } else if (record === 'reduce_tier') {
          return <Tag color="volcano">Downgrade</Tag>;
        } else if (record === 'refund' ) {
          return <Tag color="orange">Refund</Tag>;
        } else if (record === 'update') {
          return <Tag color="gold">Update</Tag>;
        } else {
          return <Tag>{record.toUpperCase()}</Tag>;
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Activate',
          value: 'activate',
        },
        {
          text: 'Upgrade',
          value: 'enhance_tier',
        },
        {
          text: 'Downgrade',
          value: 'reduce_tier',
        },
        {
          text: 'Refund',
          value: 'refund',
        },
        {
          text: 'Update',
          value: 'update',
        },
      ],
      onFilter: (value, record) => record.action === value,
    },
    {
      title: 'Created At',
      align: 'center',
      dataIndex: 'createdAt',
      width: '10%',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        );
      }
    },
    {
      title: 'Activation Email',
      width: '12%',
      ...getColumnSearchProps('activation_email'),
    },
    {
      title: 'Invoice Item UUID',
      width: '20%',
      ...getColumnSearchProps('invoice_item_uuid'),
    },
    {
      title: 'Plan',
      width: '8%',
      ...getColumnSearchProps('plan_id'),
    },
    {
      title: 'UUID',
      width: '20%',
      ...getColumnSearchProps('uuid'),
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{marginBottom: 18}}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
              <Col className="gutter-row" span={12}>
                <h2>AppSumo</h2>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <RangePicker
                  ranges={{
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'This Week': [moment().startOf('week'), moment().endOf('week')],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
                    'This Year': [moment().startOf('year'), moment().endOf('year')],
                  }}
                  onChange={onChange}
                  defaultValue={[moment().subtract(3, 'months'), moment()]}
                />
                <Button 
                  type="primary" 
                  style={{marginLeft: 24}} 
                  icon={<SearchOutlined />} 
                  disabled={!fromDate || !toDate}
                  onClick={() => {
                    //setLoading(true)
                    let payload = {
                      fromDate: fromDate.format('YYYY-MM-DD'),
                      toDate: toDate.format('YYYY-MM-DD')
                    };

                    appSumoList(payload)
                    .then(result => {
                      setUsersFilter(result)
                      //setLoading(false)
                    })
                    .catch(err => {
                      //setLoading(false)
                    })
                  }}
                >Search</Button>
                <Button
                style={{ float: 'right' }}
                type={'link'}
                icon={<DownloadOutlined />}
                onClick={() => {
                  const templateData = usersFilter.map(item => {
                    return {
                      "action":  item?.action,
                      "plan":  item?.plan_id,
                      'email': item?.activation_email,
                    };
                  });

                  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                  const fileExtension = '.xlsx';
                  const ws = XLSX.utils.json_to_sheet(templateData);
                  const wb = { Sheets: { Data: ws }, SheetNames: ['Data'] };

                  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                  const data = new Blob([excelBuffer], { type: fileType });
                  const fileName = `appsumo_${moment().format("MMMM_Do_YYYY_h_mm").trim()}`;
                  FileSaver.saveAs(data, fileName + fileExtension);
                }}
              >Download</Button>
              </Col>
            </Row>
        </Card>

        <Card>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col span={4}>
              <Card>
                <Statistic
                  title="Total"
                  loading={loading}
                  value={usersFilter?.length}
                  valueStyle={{
                    color: '#4a90e2',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={4}>
              <Card>
                <Statistic
                  title="Activate"
                  loading={loading}
                  value={usersFilter.filter(x => x.action === 'activate')?.length}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={4}>
              <Card>
                <Statistic
                  title="Upgrade"
                  loading={loading}
                  value={usersFilter.filter(x => x.action === 'enhance_tier')?.length}
                  valueStyle={{
                    color: '#60D7A7',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={4}>
              <Card>
                <Statistic
                  title="Downgrade"
                  loading={loading}
                  value={usersFilter.filter(x => x.action === 'reduce_tier')?.length}
                  valueStyle={{
                    color: '#5E92F6',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={4}>
              <Card>
                <Statistic
                  title="Refund"
                  loading={loading}
                  value={usersFilter?.filter(x => x.action === 'refund')?.length}
                  valueStyle={{
                    color: '#F5BC32',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={4}>
              <Card>
                <Statistic
                  title="Update"
                  loading={loading}
                  value={usersFilter?.filter(x => x.action === 'update')?.length}
                  valueStyle={{
                    color: '#7064F5',
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Card>
        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={usersFilter}
          pagination={{
            defaultPageSize: 20
          }}
        />
      </Layout>
    </Layout>

  )
};

export default AppSumos;