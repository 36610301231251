/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';

import { Layout, Button, Table, Modal, Row, Col, Input, Card, Space, Tag, message } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import useAuth from 'hooks/useAuth';
import { userListDowngrade, userDowngradeFree } from 'apis/user';
import moment from 'moment';

import './styles.less';

const Users = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState([]);

  const searchInputEl = useRef(null);
  const [, setUsers] = useState([]);

  const [usersFilter, setUsersFilter] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');

  useEffect(() => {
    (async () => {
      try {
        //setLoading(true)
        if (user) {
          //fetch data
          setLoading(true)
          const [result] = await Promise.all([
            userListDowngrade({}),
          ]);

          setUsers(result)
          setUsersFilter(result)
          setLoading(false)
        }
      } catch (e) {
        setLoading(false)
      }
    })();
  }, [user])

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputEl}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      const [firstValue, ...restValue] = value;

      if (dataIndex === "usernameReferral" && firstValue === "!") {
        return record[dataIndex]
          ? !record[dataIndex].toString().toLowerCase().includes(restValue.join("").toLowerCase())
          : true;
      }

      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '';
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <div>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? (dataIndex === "source" ? `${text[dataIndex].charAt(0).toUpperCase()}${text[dataIndex].slice(1)}`.toString() : text[dataIndex].toString()) : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const columns = [
    {
      title: 'Created At',
      align: 'center',
      dataIndex: 'createdAt',
      width: '10%',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY-MM-DD')}</div>
          </div>
        );
      }
    },
    {
      title: 'Email',
      width: '18%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Source',
      width: '8%',
      ...getColumnSearchProps('source'),
    },
    {
      title: 'Role',
      align: 'center',
      render: (record) => {
        //console.log('record: ', record)
        if (record?.role === 'free') {
          return (
            <div>
              <Tag>{record?.role?.toUpperCase()}</Tag>
              {record?.gumroad?.quantity && (
                <div style={{fontSize: 10, marginTop: 5, marginLeft: -5}}>{record?.gumroad?.quantity} SEAT</div>
              )}
              {record?.quantity && (
                <div style={{fontSize: 10, marginTop: 5, marginLeft: -5}}>{record?.quantity} SEAT</div>
              )}
              {(record?.payments && record?.payments?.length > 0) && (
                <a target='_blank' rel="noopener noreferrer" href={`/v1/users/sales?email=${record?.email}`} style={{fontSize: 10, marginTop: 5, marginLeft: -5}}>{record?.payments?.length} Payment</a>
              )}
          </div>
          );
        } else {
          return <div>
            <Tag color="green">{record?.role?.toUpperCase()}</Tag>
            {record?.gumroad?.quantity && (
              <div style={{fontSize: 10, marginTop: 5, marginLeft: -5}}>{record?.gumroad?.quantity} SEAT</div>
            )}
            {record?.quantity && (
              <div style={{fontSize: 10, marginTop: 5, marginLeft: -5}}>{record?.quantity} SEAT</div>
            )}
            {(record?.payments && record?.payments?.length > 0) && (
              <a target='_blank' rel="noopener noreferrer" href={`/v1/users/sales?email=${record?.email}`} style={{fontSize: 10, marginTop: 5, marginLeft: -5}}>{record?.payments?.length} Payment</a>
            )}
          </div>;
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Free',
          value: 'free',
        },
        {
          text: 'Pre',
          value: 'pre',
        },
      ],
      onFilter: (value, record) => record.role === value,
    },
    {
      title: 'EndTime',
      align: 'center',
      dataIndex: 'endTime',
      width: '10%',
      sorter: (a, b) => moment(a.endTime).unix() - moment(b.endTime).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.endTime).format('YYYY-MM-DD')}</div>
          </div>
        );
      }
    },
    {
      title: 'Expired',
      align: 'center',
      dataIndex: 'isAfter',
      render: (record) => {
        if (record === false) {
          return <Tag color="red">Expired</Tag>;
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Expired',
          value: false,
        },
      ],
      onFilter: (value, record) => record.isAfter === value,
    },
    {
      width: '8%',
      key: "action",
      render: (record) =>
        <div>
          {(user?.role === "super") && (
            <>
              <Button
                  type='link'
                  ghost
                  onClick={() => {
                    Modal.confirm({
                      title: 'Confirm',
                      icon: <ExclamationCircleOutlined />,
                      content: 'Are you sure to downgrade free?',
                      okText: 'Ok',
                      cancelText: 'Không',
                      onOk: () => {
                        let userId = record?.userId;
                        let payload = {
                          userId,
                        }
                        userDowngradeFree(payload)
                          .then(async () => {
                            message.success('Downgrade free is successful')

                            setLoading(true)
                            let usersFilterUpdate = usersFilter.map(item => {
                              if (item.userId === record?.userId) {
                                return {
                                  ...item,
                                  role: 'free',
                                }
                              } else {
                                return item
                              }
                            })
                            setUsersFilter(usersFilterUpdate)
                            setLoading(false)
                          })
                          .catch(err => console.log(err))
                      }
                    });
                  }}
                >Downgrade</Button>
            </>
          )
          }
        </div>
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Users for Downgrade ({usersFilter?.length})</h2>
            </Col>
            <Col span={12} align="right">
              <Button
                type="primary"
                style={{ marginLeft: 24 }}
                onClick={async () => {
                  Modal.confirm({
                    title: 'Confirm',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Are you sure to downgrade all free?',
                    okText: 'Ok',
                    cancelText: 'Không',
                    onOk: async () => {
                      Promise.all(usersFilter.map(async item => {
                        let userId = item?.userId;
                        let payload = {
                          userId,
                        }
                        await userDowngradeFree(payload)
                              .then(async () => {
                                setLoading(true)
                                let usersFilterUpdate = usersFilter.map(item => {
                                  if (item.userId === item?.userId) {
                                    return {
                                      ...item,
                                      role: 'free',
                                    }
                                  } else {
                                    return item
                                  }
                                })
                                setUsersFilter(usersFilterUpdate)
                                setLoading(false)
                              })
                      }))
                      message.success('Downgrade all to free is successful')
                    }
                  });
                }}
              >
                Downgrade all
              </Button>
            </Col>
          </Row>
        </Card>

        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={usersFilter}
          pagination={{
            defaultPageSize: 100
          }}
          rowKey={(row) => row.userId}
        />
      </Layout>
    </Layout>
  )
};

export default Users;