/* eslint-disable */
import request from "utils/request";

export const enterpriseList = async (payload, token) => {
    return request(`admin/enterprises`, payload, { method: "GET", token })
};

export const enterpriseDetail = async (payload, token) => {
    return request(`admin/enterprise/${payload}`, {}, { method: "GET", token })
};

export const enterpriseCreate = async (payload, token) => {
    return request(`admin/enterprise`, payload, { method: "POST", token })
};

export const enterpriseUpdate = async (payload, token) => {
    return request(`admin/enterprise`, payload, { method: "PUT", token })
};

export const enterpriseExtend = async (payload, token) => {
    return request(`admin/enterprise/extend`, payload, { method: "PUT", token })
};

export const enterpriseDowngrade = async (payload) => {
    return request(`admin/enterprise/downgrade`, payload, { method: "POST" })
};
