import { create } from "zustand";


export const usePaymentChartData = create()((set) => ({
  daysData: {},
  setDaysData: (value) => set({ daysData: value }),
  chartData: [],
  setChartData: (value) => set({ chartData: value }),
  lastUserId: null,
  setLastUserId: (value) => set({ lastUserId: value }),
  loading: false,
  setLoading: (value) => set({ loading: value }),
  onReset: () => set((state) => ({
    daysData: {},
    chartData: [],
    lastUserId: null,
    loading: false
  }))
}));

export const useUsageChartData = create()((set) => ({
  daysData: {},
  setDaysData: (value) => set({ daysData: value }),
  chartData: [],
  setChartData: (value) => set({ chartData: value }),
  lastUserId: null,
  setLastUserId: (value) => set({ lastUserId: value }),
  loading: false,
  setLoading: (value) => set({ loading: value }),
  onReset: () => set((state) => ({
    daysData: {},
    chartData: [],
    lastUserId: null,
    loading: false
  }))
}));
