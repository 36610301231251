import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Form, Input, Col, Row, Button, Select, Checkbox, Avatar, message, Modal, Spin, InputNumber } from 'antd';

import ReactQuill from 'react-quill';
import { QuillToolbarContainer, QuillFormats } from 'utils/quill';

import { announcementCreate } from 'apis/announcements';
import { uploadFile } from 'apis/other';

import '../../../../../node_modules/react-quill/dist/quill.snow.css';
import 'react-image-crop/dist/ReactCrop.css';
import './styles.less';

const AnnouncementCreateForm = ({ token, onCancel, onCallback }) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  
  const [bodyVn, setBodyVn] = useState('');
  const [bodyEn, setBodyEn] = useState('');
  const [bodyJp, setBodyJp] = useState('');
  
  const quillRefVn = useRef();
  const imageHandlerVn = () => {
    const quillEditor = quillRefVn.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input.files[0]
      
      uploadFile(file)
      .then(url => {
        const range = quillEditor.getSelection();
        quillEditor.insertEmbed(range.index, 'image', url)
      })
      .catch(error => console.log(error));
    }
  }
  const modulesVn = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
      handlers: {
        'image': imageHandlerVn
      }
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  const quillRefEn = useRef();
  const imageHandlerEn = () => {
    const quillEditor = quillRefEn.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input.files[0]
      
      uploadFile(file)
      .then(url => {
        const range = quillEditor.getSelection();
        quillEditor.insertEmbed(range.index, 'image', url)
      })
      .catch(error => console.log(error));
    }
  }
  const modulesEn = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
      handlers: {
        'image': imageHandlerEn
      }
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  const quillRefJp = useRef();
  const imageHandlerJp = () => {
    const quillEditor = quillRefJp.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input.files[0]
      
      uploadFile(file)
      .then(url => {
        const range = quillEditor.getSelection();
        quillEditor.insertEmbed(range.index, 'image', url)
      })
      .catch(error => console.log(error));
    }
  }
  const modulesJp = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
      handlers: {
        'image': imageHandlerJp
      }
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  return (
    <Form
        form={form}
        layout="vertical"
        className="announcement-modal"
        onFinish={values => {
          setLoading(true);
          let item = {}

          if (values?.titleVn) {
            item.titleVn = values?.titleVn;
          }

          if (values?.titleEn) {
            item.titleEn = values?.titleEn;
          }

          if (values?.titleJp) {
            item.titleJp = values?.titleJp;
          }

          if (values?.actionTitleVn) {
            item.actionTitleVn = values?.actionTitleVn;
          }

          if (values?.actionTitleEn) {
            item.actionTitleEn = values?.actionTitleEn;
          }

          if (values?.actionTitleJp) {
            item.actionTitleJp = values?.actionTitleJp;
          }


          if (values?.actionLink) {
            item.actionLink = values?.actionLink;
          }

          if (bodyVn) {
            item.bodyVn = bodyVn;
          } else {
            item.bodyVn = '';
          }

          if (bodyEn) {
            item.bodyEn = bodyEn;
          } else {
            item.bodyEn = '';
          }

          if (bodyJp) {
            item.bodyJp = bodyJp;
          } else {
            item.bodyJp = '';
          }

          if (values?.isPushNotification) {
            item.isPushNotification = values?.isPushNotification;
          } else {
            item.isPushNotification = false;
          }

          if (values?.isSendEmail) {
            item.isSendEmail = values?.isSendEmail;
          } else {
            item.isSendEmail = false;
          }

          if (values?.isActive) {
            item.isActive = values?.isActive;
          } else {
            item.isActive = false;
          }

          announcementCreate(item, token)
          .then(async(result)=>  {
            setLoading(false)
                onCancel()
                onCallback(result.announcementId)

            //send notification to all devices
            // if (values?.isPushNotification === true) {
            //   let fcmTokens = await fcmList(token);

            //   await fcmSend({
            //     title: item.title,
            //     message: result.excerpt,
            //     tokens: fcmTokens.map(x => x.fcmToken),
            //   }, token)
            // }

            //send email to all users
            // if (values?.isSendEmail === true) {
            //   let users = await userList({}, token);
              
            //   await Promise.all(users.map(async user => {
            //     const payload = {
            //       email: user?.email,
            //       param: {
            //         body: item.body,
            //         name: user?.name,
            //       },
            //       template: 'announcement',
            //       subject: item.title,
            //       locale: 'vi',
            //     };

            //     if (user?.email === 'toan.dev101@gmail.com') {
            //       await mailerSend(payload, token);
            //     }
            //   }))
            // }           
          })
        }}
        initialValues={{
          isActive: true,
        }}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="titleVn" 
              label="Tiêu đề (Vn)" 
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập Tiêu đề!'
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="titleEn" 
              label="Tiêu đề (En)" 
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập Tiêu đề!'
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="titleJp" 
              label="Tiêu đề (Jp)" 
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập Tiêu đề!'
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item 
              name="actionTitleVn" 
              label="Kêu gọi hành động Tiêu đề (Vn)"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item 
              name="actionTitleEn" 
              label="Kêu gọi hành động Tiêu đề (En)"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item 
              name="actionTitleJp" 
              label="Kêu gọi hành động Tiêu đề (Jp)"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item 
              name="actionLink" 
              label="Kêu gọi hành động (Href)" 
              rules={[
                {
                  message: 'Kêu gọi hành động (Href)!'
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
          
        <Row gutter={24} type="flex">
          <Col lg={{ span: 4 }} xs={{ span: 12 }}>
            <Form.Item name="isPushNotification" label="Push Notification" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
          <Col lg={{ span: 4 }} xs={{ span: 12 }}>
            <Form.Item name="isSendEmail" label="Send Email" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item label="Nội dung (Vn)">
              <ReactQuill
                ref={quillRefVn}
                modules={modulesVn}
                formats={QuillFormats}
                value={bodyVn}
                onChange={(value) => setBodyVn(value)}
              />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item label="Nội dung (En)">
              <ReactQuill
                ref={quillRefEn}
                modules={modulesEn}
                formats={QuillFormats}
                value={bodyEn}
                onChange={(value) => setBodyEn(value)}
              />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item label="Nội dung (Jp)">
              <ReactQuill
                ref={quillRefJp}
                modules={modulesJp}
                formats={QuillFormats}
                value={bodyJp}
                onChange={(value) => setBodyJp(value)}
              />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 6 }} xs={{ span: 12 }}>
            <Form.Item name="isActive" label="Kích hoạt" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            {loading === false ?
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              <Button type="clear" onClick={onCancel}>
                  Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                  Save
              </Button>
            </Col>
            :
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              <Button type="clear">
                  Cancel
              </Button>
              <Button type="primary" loading>
                  Save
              </Button>
            </Col>
            }
          </Row>
        </div>
      </Form>
    
  );
};

export default AnnouncementCreateForm;