import React, { useState, useRef, useMemo } from 'react';
import { Form, Input, Col, Row, Button, Select, InputNumber, Checkbox, message } from 'antd';
import { versionCreate } from 'apis/version'
import { storage } from 'utils/firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import ReactQuill from 'react-quill';
import '../../../../../node_modules/react-quill/dist/quill.snow.css';

import { QuillToolbarContainer, QuillFormats } from 'utils/quill';

import './styles.less';

const { Option } = Select;

const VersionCreateForm = ({ onCancel, onCallback, minBuild }) => {
  const [form] = Form.useForm();
  const [releaseNotesVn, setReleaseNotesVn] = useState('');
  const quillRefVn = useRef(null);

  const [releaseNotesEn, setReleaseNotesEn] = useState('');
  const quillRefEn = useRef(null);

  const [releaseNotesJp, setReleaseNotesJp] = useState('');
  const quillRefJp = useRef(null);

  const [file, setFile] = useState("");
  const [percent, setPercent] = useState(0);
  const [showPercent, setShowPercent] = useState(false);

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  function handleUpload(values) {
    if (!file) {
        alert("Please choose a file first!")
    }

    const fileRef = ref(storage, `download/${file.name}`);
    setShowPercent(true);
    const uploadTask = uploadBytesResumable(fileRef, file)
 
    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
 
            // update progress
            setPercent(percent);
        },
        (err) => console.log(err),
        () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                let payload = {
                  ...values,
                  isPreviewUpdate: values?.isPreviewUpdate ? true : false,
                  isForceUpdate: values?.isForceUpdate ? true : false,
                  urlDownload: url,
                  releaseNotesVn, 
                  releaseNotesEn,
                  releaseNotesJp, 
                }

                let result = await versionCreate(payload);
                if (result.status === 'success') {
                  onCancel()
                  onCallback()
                } else {
                  message.error(result?.error?.message);
                }
            });
        }
    ); 
  }

  const modulesBodyVn = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  const modulesBodyEn = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  const modulesBodyJp = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  const [isPreviewUpdate, setIsPreviewUpdate] = useState(false);
  const [isForceUpdate, setIsForceUpdate] = useState(false);
  return (
    <Form
        form={form}
        className="version-modal"
        layout="vertical"
        name="pageCreateForm"
        onFinish={values => {
          handleUpload(values);
        }}
        initialValues={{}}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item 
              name="os"
              label="Os"
            >
              <Select
                showSearch
                placeholder="Os"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={'win'}>Win</Option>
                <Option value={'mac'}>Mac</Option>
                <Option value={'linux'}>Linux</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item 
              name="version" 
              label="Version" 
              rules={[
                {
                  required: true,
                  message: 'Version!'
                }
              ]}>
              <Input placeholder='1.0.1'/>
            </Form.Item>
          </Col>
          <Col lg={{ span: 4 }} xs={{ span: 24 }}>
            <Form.Item 
              name="builderNumber" 
              label="Builder Number" 
              rules={[
                {
                  required: true,
                  message: 'Build Number!'
                }
              ]}>
              <InputNumber min={minBuild} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 4 }} xs={{ span: 24 }}>
            <Form.Item name="isPreviewUpdate" label="Preview Update" valuePropName="checked">
              <Checkbox 
                disabled={isForceUpdate}
                defaultChecked={isPreviewUpdate} 
                onChange={(e) => setIsPreviewUpdate(e.target.checked)}
              />
            </Form.Item>
          </Col>
          <Col lg={{ span: 4 }} xs={{ span: 24 }}>
            <Form.Item name="isForceUpdate" label="Force Update" valuePropName="checked">
              <Checkbox
                disabled={isPreviewUpdate}
                defaultChecked={isForceUpdate} 
                onChange={(e) => setIsForceUpdate(e.target.checked)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="nameVn" 
              label="Name (Vn)" 
              rules={[
                {
                  required: true,
                  message: 'Name (Vn)!'
                }
              ]}>
              <Input placeholder='Mac 1.28.7 - Hỗ trợ chíp M1'/>
            </Form.Item>
          </Col>
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="nameEn" 
              label="Name (En)" 
              rules={[
                {
                  required: true,
                  message: 'Name (En)!'
                }
              ]}>
              <Input placeholder='Mac 1.28.7 - Native support on M1 chip'/>
            </Form.Item>
          </Col>
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="nameJp" 
              label="Name (Jp)" 
              rules={[
                {
                  required: true,
                  message: 'Name (Jp)!'
                }
              ]}>
              <Input placeholder='Mac 1.28.7 - M1チップでのネイティブサポート'/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              label="Upload File" 
            >
              <Input type="file" onChange={handleChange}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item label="Release Notes (Vn)">
              <ReactQuill
                ref={quillRefVn}
                modules={modulesBodyVn}
                formats={QuillFormats}
                value={releaseNotesVn}
                placeholder=""
                onChange={(value) => setReleaseNotesVn(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item label="Release Notes (En)">
              <ReactQuill
                ref={quillRefEn}
                modules={modulesBodyEn}
                formats={QuillFormats}
                value={releaseNotesEn}
                placeholder=""
                onChange={(value) => setReleaseNotesEn(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item label="Release Notes (Jp)">
              <ReactQuill
                ref={quillRefJp}
                modules={modulesBodyJp}
                formats={QuillFormats}
                value={releaseNotesJp}
                placeholder=""
                onChange={(value) => setReleaseNotesJp(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              {showPercent && <p>Upload Percent: {percent}%</p>}
              <Button type="clear" onClick={onCancel}>
                  Cancel
              </Button>
              <Button type="primary" htmlType="submit" disabled={showPercent}>
                  {showPercent ? "Uploading..." : "Save"}
              </Button>
            </Col>
          </Row>
        </div>
  
      </Form>
    
  );
};

export default VersionCreateForm;