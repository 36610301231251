import React, { useState, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import './styles.less';

import { Modal, Button, Table, Card, Row, Col, Avatar, Tag } from 'antd';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';

import { authorList, authorDetail } from 'apis/authors';
import AuthorCreateForm from './forms/author-create.form';
import AuthorUpdateForm from './forms/author-update.form';

import useToken from 'hooks/useToken';
import SidebarLayout from 'layouts/sidebar';

const AuthorPage = () => {
  const { token } = useToken();
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [author, setAuthor] = useState({});
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (token) {
      authorList(token)
        .then(result => {
          console.log(result)
          setAuthors(result)
          setLoading(false)
        });
    }
  }, [token]);

  const openUpdateModal = (authorId) => {
    const author = authors.find(item => item.authorId === authorId);
    setAuthor(author);
    setIsUpdateModal(true);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {
      title: 'Photo',
      width: '7%',
      align: 'center',
      render: (record) => {
        if (_.get(record, 'photo', '') === '') {
          return (
            <div>
              <Avatar shape='circle' icon={<UserOutlined />} size={80} />
            </div>
          )
        } else {
          return (
            <div>
              <Avatar shape='circle' src={_.get(record, 'photo', '')} size={80} />
            </div>
          )
        }
      }
    },
    {
      title: 'Lang',
      dataIndex: 'lang',
      width: '5%'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '20%'
    },
    {
      title: 'Last Update',
      align: 'center',
      dataIndex: 'updatedAt',
      width: '7%',
      render: (text, record, index) => {
        return (
          `${moment(record.createdAt).format('YYYY-MM-DD')}`
        );
      }
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      align: 'center',
      width: '6%',
      render: (record) => {
        if (record === true)
          return (
            <Tag>Active</Tag>
          )
        else {
          return (
            <Tag color={'red'}>In-active</Tag>
          )
        }
      }
    },
    {
      title: '',
      align: 'center',
      width: '6%',
      render: (record) => {
        return (
          <Button type="primary" onClickCapture={() => openUpdateModal(record.authorId)}>Edit</Button>
        );
      }
    }
  ];

  return (
    // <SidebarLayout className="tags-page" defaultKey="4">
    <>
      <Card style={{ marginBottom: 18 }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
          <Col className="gutter-row" span={12}>
            <h2>Tác giả</h2>
          </Col>
          <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={() => setIsCreateModal(true)} icon={<PlusOutlined />}>Thêm mới</Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={loading}
        bordered
        columns={columns}
        dataSource={authors}
        onChange={(pagination, filters, sorter) => {
          setCurrentPage(pagination.current);
        }}
        pagination={{
          total: authors.lenght,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          pageSize: 20,
          current: currentPage
        }}
      />
      <Modal
        visible={isCreateModal}
        title="Add Author"
        onCancel={() => setIsCreateModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <AuthorCreateForm
          token={token}
          onCancel={() => setIsCreateModal(false)}
          onCallback={(authorId) => {
            setLoading(true)
            authorDetail(authorId, token)
              .then(author => {
                //Update List
                let update = [author, ...authors]
                setAuthors((update))
                setLoading(false)
              })
              .catch(err => setLoading(false))

            setIsCreateModal(false)
          }}
        />
      </Modal>
      <Modal
        visible={isUpdateModal}
        title="Update Author"
        onCancel={() => setIsUpdateModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <AuthorUpdateForm
          token={token}
          author={author}
          onCancel={() => setIsUpdateModal(false)}
          onCallback={(authorId) => {
            setLoading(true)

            authorDetail(authorId, token)
              .then(author => {
                //Update List
                let update = authors.map(item => {
                  if (item.authorId === author.authorId) {
                    return author
                  } else {
                    return item
                  }
                })
                setAuthors(update)
                setLoading(false)
              })
              .catch(err => setLoading(false))

            setIsUpdateModal(false)
          }}
        />
      </Modal>
      {/* </SidebarLayout> */}
    </>
  )
};

export default AuthorPage;