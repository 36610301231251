import React, { useState, useEffect, useRef } from 'react';
import { Layout, Button, Table, Modal, Row, Col, Input, Card, Space, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import moment from 'moment';
import useToken from 'hooks/useToken';
import { gumroadList, gumroadDeleteAccount, gumroadMigrationAccount } from 'apis/gumroad';

import './styles.less';
const SuperUsers = () => {
  const { token } = useToken();
  const [loading, setLoading] = useState([]);

  const searchInputEl = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [items, setItems] = useState([]);

  const [expandedKey, setExpandedKey] = useState([]);
  const onExpand = (_, { gumroadId }) => expandedKey.includes(gumroadId) ? setExpandedKey(prev => prev.filter(item => item !== gumroadId)) : setExpandedKey(prev => [...prev, gumroadId]);

  useEffect(() => {
    (async () => {
      if (token) {
        try {
          setLoading(true);
          const [result] = await Promise.all([
            gumroadList(token)
          ]);

          setItems(result.filter(x => x.email === x.gumroad?.email).map(item => {
            return {
              ...item,
              users: result.filter(y => item.gumroad?.email === y.gumroad?.email)
            }
          }));

          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      }
    })();
  }, [token]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ""
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div>
              {(dataIndex === "email" && text?.appsumo !== undefined) && (
                <span style={{ fontWeight: "bold", marginRight: 5, color: "orange" }}>[AppSumo]</span>
              )}
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? text[dataIndex].toString() : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const columns = [
    {
        title: "Action",
        align: "center",
        width: "10%",
        render: (record) => {
          return (
            <div>
              <Button
                type='link'
                ghost
                onClick={() => {
                  Modal.confirm({
                    title: 'Confirm',
                    content: 'Are you sure to migration this account?',
                    okText: 'Ok',
                    cancelText: 'No',
                    onOk: () => {
                        gumroadMigrationAccount(record)
                        .then(async (data) => {
                          setLoading(true)
                          
                          const [result] = await Promise.all([
                            gumroadList(token)
                          ]);
                
                          setItems(result.filter(x => x.email === x.gumroad?.email).map(item => {
                            return {
                              ...item,
                              users: result.filter(y => item.gumroad?.email === y.gumroad?.email)
                            }
                          }));
                          setLoading(false)
                        })
                        .catch(err => console.log(err))
                    }
                  });
                }}
              >Migration account</Button>
            </div>
          );
        }
    },
    {
        title: 'Migration',
        dataIndex: 'isMigration',
        render: (record) => {
          if (record === true) {
            return <Tag color="red">Yes</Tag>;
          }
        },
        width: '10%',
        align: 'center',
        filterMultiple: true,
        filters: [
          {
            text: 'Migration',
            value: true,
          },
        ],
        onFilter: (value, record) => record.isMigration === value,
      },
    {
      title: "Created At",
      align: "center",
      dataIndex: "createdAt",
      width: "15%",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        );
      }
    },
    {
      title: "Email",
      width: "25%",
      ...getColumnSearchProps("email"),
    },
    {
      title: 'Tier',
      align: 'center',
      dataIndex: 'gumroad',
      width: '15%',
      render: (text, record, index) => {
        return (
          <div style={{fontWeight: 'bold'}}>{record.gumroad?.variants["Tier"]}</div>
        );
      },
      filterMultiple: true,
      filters: [
        {
          text: 'Premium',
          value: 'Premium',
        },
        {
          text: 'Team of 2',
          value: 'Team of 2',
        },
        {
          text: 'Team of 3',
          value: 'Team of 3',
        },
        {
            text: 'Team of 5',
            value: 'Team of 5',
          },
      ],
      onFilter: (value, record) => record.gumroad?.variants["Tier"] === value,
    },
    {
      title: 'Activated Users',
      align: 'center',
      dataIndex: 'users',
      width: '15%',
      render: (text, record, index) => {
        return (
          <div style={{fontWeight: 'bold'}}>{record.users?.filter(x => x.role === 'pre')?.length}</div>
        );
      }
    },
    {
      title: 'Role',
      align: 'center',
      dataIndex: 'role',
      render: (record) => {
        if (record === 'free') {
          return <Tag>{record.toUpperCase()}</Tag>;
        } else {
          return <Tag color="green">{record.toUpperCase()}</Tag>;
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Free',
          value: 'free',
        },
        {
          text: 'Trial',
          value: 'trial',
        },
        {
          text: 'Pre',
          value: 'pre',
        },
      ],
      onFilter: (value, record) => record.role === value,
    },
    {
      title: 'EndTime',
      align: 'center',
      dataIndex: 'endTime',
      width: '10%',
      sorter: (a, b) => moment(a.endTime).unix() - moment(b.endTime).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.endTime).format('YYYY-MM-DD')}</div>
          </div>
        );
      }
    },
    {
      title: "Action",
      align: "center",
      width: "10%",
      render: (record) => {
        return (
          <div>
            <Button
              type='link'
              ghost
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm',
                  content: 'Are you sure to delete this account?',
                  okText: 'Ok',
                  cancelText: 'No',
                  onOk: () => {
                    gumroadDeleteAccount(record)
                      .then(async (data) => {
                        setLoading(true)
                        
                        const [result] = await Promise.all([
                          gumroadList(token)
                        ]);
              
                        setItems(result.filter(x => x.email === x.gumroad.email).map(item => {
                          return {
                            ...item,
                            users: result.filter(y => item.email === y.gumroad.email && y.email !== y.gumroad.email)
                          }
                        }));
                        setLoading(false)
                      })
                      .catch(err => console.log(err))
                  }
                });
              }}
            >Delete account</Button>
          </div>
        );
      }
    }
  ];

  const columnsUser = [
    {
      title: "Created At",
      align: "center",
      dataIndex: "createdAt",
      width: "10%",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        );
      }
    },
    {
      title: "Email",
      width: "35%",
      ...getColumnSearchProps("email"),
    },
    {
      title: 'Role',
      align: 'center',
      dataIndex: 'role',
      render: (record) => {
        if (record === 'free') {
          return <Tag>{record.toUpperCase()}</Tag>;
        } else {
          return <Tag color="green">{record.toUpperCase()}</Tag>;
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Free',
          value: 'free',
        },
        {
          text: 'Trial',
          value: 'trial',
        },
        {
          text: 'Pre',
          value: 'pre',
        },
      ],
      onFilter: (value, record) => record.role === value,
    },
    {
      title: 'EndTime',
      align: 'center',
      dataIndex: 'endTime',
      width: '10%',
      sorter: (a, b) => moment(a.endTime).unix() - moment(b.endTime).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        console.log('record: ', record)
        return (
          <div>
            <div>{moment(record?.endTime).format('YYYY-MM-DD')}</div>
          </div>
        );
      }
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Gumroad Users (OLD)</h2>
            </Col>
          </Row>
        </Card>

        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          pagination={{
            defaultPageSize: 20
          }}
          rowKey={(row) => row.gumroadId}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                loading={loading}
                bordered
                columns={columnsUser}
                dataSource={record.users}
                pagination={false}
              />
            ),
            onExpand: onExpand,
            expandedRowKeys: [...expandedKey]
          }}
        />

      </Layout>
    </Layout>

  )
};

export default SuperUsers;