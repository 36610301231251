import { create } from "zustand";


export const useUserHistory = create()((set) => ({
  usersHistory: [],
  setUsersHistory: (value) => set({ usersHistory: value }),
  userIdHistory: [],
  setUserIdHistory: (value) => set({ userIdHistory: value }),
  addUserIdHistory: (userIds) =>
    set((state) => ({
      userIdHistory: [...state.userIdHistory, userIds],
    })),
  initialLoading: true,
  setInitialLoading: (value) => set({ initialLoading: value }),
  loading: false,
  setLoading: (value) => set({ loading: value }),
  isPaused: false,
  setIsPaused: (value) => set({ isPaused: value }),
  isCompleted: false,
  setIsCompleted: (value) => set({ isCompleted: value }),
  limitHistory: 1000,
  setLimitHistory: (value) => set({ limitHistory: value }),
  pageHistory: 1,
  setPageHistory: (value) => set({ pageHistory: value }),
  incrementPageHistory: () => set((state) => ({ pageHistory: state.pageHistory + 1 })),
  totalPageHistory: 1,
  setTotalPageHistory: (value) => set({ totalPageHistory: value }),
  total: 1,
  setTotal: (value) => set({ total: value }),
  onReset: () => set((state) => ({
    usersHistory: [],
    userIdHistory: [],
    initialLoading: true,
    isCompleted: false,
    pageHistory: 1,
    totalPageHistory: 1,
    total: 1,
    loading: false,
    isPaused: false,
    limitHistory: 1000
  }))
}));
