import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Form, Input, Col, Row, Button, Modal, DatePicker, InputNumber } from 'antd';
import { Select, Checkbox, Avatar, Spin, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import ReactCrop from "react-image-crop";
import ReactQuill, { Quill } from 'react-quill';
import '../../../../../node_modules/react-quill/dist/quill.snow.css';
import { QuillToolbarContainer, QuillFormats } from 'utils/quill';

import { Timestamp } from "firebase/firestore";
import { uploadFile } from 'apis/other';
import { postCreate } from 'apis/posts';

import 'react-image-crop/dist/ReactCrop.css';
import './styles.less';
import slugify from 'slugify';

const { Option } = Select;

const AlignStyle = Quill.import('attributors/style/align');
const BlockEmbed = Quill.import('blots/block/embed');

class AudioBlot extends BlockEmbed {
  static create(url) {
    let node = super.create();
    node.setAttribute("src", url);
    node.setAttribute("controls", "");
    return node;
  }

  static value(node) {
    return node.getAttribute("src");
  }
};

AudioBlot.blotName = "audio";
AudioBlot.tagName = "audio";

Quill.register(AudioBlot);
Quill.register(AlignStyle, true);

const PostCreateForm = ({ token, posts, categories, tags, authors, onCancel, onCallback }) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [body, setBody] = useState('');
  const quillRef = useRef(null);

  //Image Upload
  const [photo, setPhoto] = useState('');
  const [isUploadPhoto, setIsUploadPhoto] = useState(false);
  const [photoPreview, setPhotoPreview] = useState('');
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isCropingImageModal, setIsCropingImageModal] = useState(false);
  const [upImg, setUpImg] = useState();
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 324 / 216, x: 0, y: 0 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const imageHandler = () => {
    const quillEditor = quillRef.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input.files[0]

      uploadFile(file)
        .then(url => {
          console.log('url: ', url)
          const range = quillEditor.getSelection();
          quillEditor.insertEmbed(range.index, 'image', url);
          quillEditor.setSelection(range.index + 1, "user");
        })
        .catch(error => console.log(error));
    }
  };

  const videoHandler = () => {
    const quillEditor = quillRef.current.getEditor();
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "audio/*, video/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];

      if (file.type?.includes("video")) {
        uploadFile(file)
          .then(url => {
            const range = quillEditor.getSelection();
            quillEditor.insertEmbed(range.index, "video", url);
            quillEditor.setSelection(range.index + 1, "user");
          })
          .catch(error => console.log(error));
      } else if (file.type?.includes("audio")) {
        input.setAttribute("type", "file");
        uploadFile(file)
          .then(url => {
            const range = quillEditor.getSelection();
            quillEditor.insertEmbed(range.index, "audio", url);
            quillEditor.setSelection(range.index + 1, "user");
          })
          .catch(error => console.log(error));
      }
    }
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
      handlers: {
        'image': imageHandler,
        'video': videoHandler,
      }
    },
    clipboard: {
      matchVisual: false
    }
  }), []);

  const onSelectFile = (e) => {
    //Init Crop Area Default
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size <= 5000000) {
        setUpImg(URL.createObjectURL(e.target.files[0]));
        setIsCropingImageModal(true);
        return;
      }
      message.warning('Image over 5MB')
      // reader.readAsDataURL: can't read large image data on Firefox Mobile. It's replaced by URL.createObjectURL.
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current || completedCrop.width <= 0 || completedCrop.height <= 0) {
      return;
    }

    const pixelRatio = 4;
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const handleUploadImage = (previewCanvas, crop) => {
    if (!crop || !previewCanvas || previewCanvas.width <= 0 || previewCanvas.height <= 0 || crop.width <= 0 || crop.height <= 0) {
      return;
    }

    previewCanvas.toBlob(
      (blob) => {
        setIsUploading(true);
        setPhoto(blob);
        setIsUploadPhoto(true);
        setPhotoPreview(URL.createObjectURL(blob));

        setIsUploading(false);
        setIsCropingImageModal(false);
      },
      "image/png",
      1
    );
  };

  const getCategoryName = (value) => {
    const item = categories.find(item => item.categoryId === value);
    return item?.name;
  }

  return (
    <Form
      form={form}
      className="post-modal"
      layout="vertical"
      onFinish={async values => {
        setLoading(true)

        let item = {};
        const cat = categories.find(item => item.categoryId === values.category);

        if (cat?.categoryParentId) { //menu 2 level
          item.categoryParent = cat?.categoryParentId;
        } else { //menu 1 level
          item.categoryParent = cat?.categoryId;
        }

        if (values?.no) {
          item.no = values?.no;
        }

        if (values?.slug) {
          item.slug = values?.slug;
        }

        if (values?.title) {
          item.title = values?.title;
        }

        if (values?.publishDate) {
          item.publishDate = Timestamp.fromDate(values?.publishDate.toDate());
        }

        if (values?.isActive) {
          item.isActive = values?.isActive;
        } else {
          item.isActive = false;
        }

        if (values?.isPopular) {
          item.isPopular = values?.isPopular;
        } else {
          item.isPopular = false;
        }

        if (values?.isLongform) {
          item.isLongform = values?.isLongform;
        } else {
          item.isLongform = false;
        }

        if (values?.isSEO) {
          item.isSEO = values?.isSEO;
        } else {
          item.isSEO = false;
        }

        if (values?.author) {
          item.author = values?.author;
        }

        if (values?.category) {
          item.category = values?.category;
        }

        if (values?.lang) {
          item.lang = values?.lang;
        }

        if (values?.tags) {
          item.tags = values?.tags;
        }

        if (values?.relatedPosts) {
          item.relatedPosts = values?.relatedPosts;
        }

        if (body) {
          item.body = body;
        } else {
          item.body = '';
        }

        if (isUploadPhoto) {
          let url = await uploadFile(photo);
          item.photo = url;
        }

        let result = await postCreate(item);
        if (result.status === 'success') {
          setLoading(false)
          onCancel()
          onCallback()
        } else {
          message.error(result?.error?.message);
          setLoading(false);
        }
      }}
      initialValues={{
        isTrending: false,
        isActive: false,
        lang: 'vn',
      }}
    >
      <Row gutter={24} type="flex">

        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <Form.Item
            name="slug"
            label="Slug"
            rules={[
              {
                required: true,
                message: "Please input the Slug!"
              },
              {
                pattern: new RegExp(/^[a-z0-9]+(?:-[a-z0-9]+)*$/),
                message: "Please input the correct Slug",
              }
            ]}
          >
            <Input placeholder="slug" />
          </Form.Item>
        </Col>
        <Col lg={{ span: 16 }} xs={{ span: 24 }}>
          <Form.Item
            name="title"
            label="Tiêu đề"
            rules={[
              {
                required: true,
                message: 'Please input the Title!'
              }
            ]}
          >
            <Input placeholder="Title"
              onChange={(event) => {
                // eslint-disable-next-line no-useless-escape
                const newSlug = slugify(event.target.value, { remove: /[\*\+\~\.\,\;\^\_\=\?\(\)\`\'\"\$\%\&\|\<\>\!\\\/\:\@\#\{\}\[\]]/g }).toLowerCase();
                form.setFieldsValue({ slug: newSlug });
              }}

            />
          </Form.Item>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item
            name="no"
            label="No."
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập Số thứ tự!'
              }
            ]}
          >
            <InputNumber min={0} max={1000} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 4 }}>
          <Form.Item
            label="Ngày đăng"
            name="publishDate"
            rules={[
              {
                required: true,
                message: 'Nhập ngày đăng!'
              }
            ]}
          >
            <DatePicker style={{ width: '100%' }} placeholder="Ngày đăng" />
          </Form.Item>
        </Col>

      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form.Item
            name="category"
            label="Category"
            rules={[
              {
                required: true,
                message: 'Category!'
              }
            ]}
          >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Category"
            >
              {categories.filter(x => x.isActive === true)
                .map(item => {
                  if (item?.categoryParentId) {
                    return (
                      <Option key={item.categoryId}>{getCategoryName(item?.categoryParentId)} - {item.name}</Option>
                    )
                  } else {
                    return (
                      <Option key={item.categoryId}>{item.name}</Option>
                    )
                  }
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 8 }} xs={{ span: 24 }}>
          <Form.Item
            name="author"
            label="Author"
          >
            <Select
              style={{ width: '100%' }}
              placeholder="Author"
            >
              {authors.map(item => (
                <Option key={item.authorId}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item
            name="lang"
            label="Language"
          >
            <Select
              placeholder="Language"
            >
              <Select.Option value={'vn'}>vn</Select.Option>
              <Select.Option value={'en'}>en</Select.Option>
              <Select.Option value={'jp'}>jp</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item
            name="tags"
            label="Tags">
            <Select
              showSearch
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Tags"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  option.keyword.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              }}
            >
              {tags.filter(x => (x.tagGroup === 'article' || x.tagGroup === 'coin') && x.isActive === true).map(item => (
                <Option value={item.tagId} keyword={item.name}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item label="Nội dung">
            <ReactQuill
              ref={quillRef}
              modules={modules}
              formats={QuillFormats}
              value={body}
              onChange={(value) => setBody(value)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item label="Photo" >
            <label htmlFor="photo" className="ant-btn" style={{ paddingTop: 5 }}><UploadOutlined /> Chọn photo</label>
            <Input
              id='photo'
              name="photo"
              placeholder="Photo"
              accept="image/*"
              type="file"
              hidden
              //onChange={handleChange('photo')}
              onClick={(e) => {
                e.currentTarget.value = null;
              }}
              onChange={onSelectFile}
            />
            <div style={{ alignItems: 'center', marginTop: 10 }}>
              <Avatar shape="square" size={128} src={photoPreview !== '' ? photoPreview : ''} />
            </div>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item name="isActive" label="Kích hoạt" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Col>
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <Form.Item name="isPopular" label="Đặt nổi bật" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Col>
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <Form.Item name="isLongform" label="Longform" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Col>
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <Form.Item name="isSEO" label="SEO" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item
            name="relatedPosts"
            label="Bài viết liên quan">
            <Select
              showSearch
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Related Posts"
            >
              {posts.filter(x => x.categoryId !== '' && x.isActive === true).map(item => (
                <Option key={item.postId}>{item.title}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          {loading === false ?
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Col>
            :
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear">
                Cancel
              </Button>
              <Button type="primary" loading>
                Save
              </Button>
            </Col>
          }
        </Row>
      </div>

      <Modal
        centered
        visible={isCropingImageModal}
        title={'Upload Image'}
        destroyOnClose={true}
        confirmLoading={isUploading}
        okText={'Ok'}
        cancelText={'Cancel'}
        onOk={() => {
          handleUploadImage(previewCanvasRef.current, completedCrop)
        }}
        onCancel={() => {
          if (isUploading === false) {
            setIsCropingImageModal(false)
          }
        }}
        okButtonProps={{ disabled: !completedCrop || !previewCanvasRef.current || !imgRef.current || completedCrop.width <= 0 || completedCrop.height <= 0 }}
      >
        {isUploading === true ?
          <Spin>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => {
                setCrop(c)
              }}
              onComplete={(c) => {
                setCompletedCrop(c)
              }}
            />
            <canvas ref={previewCanvasRef} style={{ display: "none" }} />
          </Spin>
          :
          <>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => {
                setCrop(c)
              }}
              onComplete={(c) => {
                setCompletedCrop(c)
              }}
            />
            <canvas ref={previewCanvasRef} style={{ display: "none" }} />
          </>
        }
      </Modal>
    </Form>
  );
};

export default PostCreateForm;