import React, { useState, useEffect, useRef } from 'react';

import { Layout, Button, Table, Modal, Row, Col, Input, Card, Space, Tag, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import moment from 'moment';
import useToken from 'hooks/useToken';
import { gumroadWebhookList, gumroadWebhook, gumroadWebhookEnded, gumroadWebhookRestarted } from 'apis/gumroad';

import './styles.less';
const SuperUsers = () => {
  const { token } = useToken();
  const [loading, setLoading] = useState([]);

  const searchInputEl = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [items, setItems] = useState([]);
  
  useEffect(() => {
    (async () => {
      if (token) {
        try {
          setLoading(true);
          const [result] = await Promise.all([
            gumroadWebhookList(token)
          ]);

          setItems(result);

          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      }
    })();
  }, [token]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ""
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div>
              {(dataIndex === "email" && text?.appsumo !== undefined) && (
                <span style={{ fontWeight: "bold", marginRight: 5, color: "orange" }}>[AppSumo]</span>
              )}
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? text[dataIndex].toString() : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const columns = [
    {
      title: "Created At",
      align: "center",
      dataIndex: "createdAt",
      width: "10%",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        );
      }
    },
    {
      title: "Email",
      width: "15%",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Payload",
      render: (record) => {
        console.log('record: ', JSON.stringify(record))
        return <div style={{maxWidth: 600}}>
          {record?.webhook_type && (
            <Tag 
              color={
                record?.type === 'refund' ? '#FF0800' : 
                record?.type === 'cancell' ? '#2db7f5' :
                record?.type === 'downgrade' ? '#f80' :
                record?.type === 'subscription_restarted' ? '#87d068' : 
                record?.type === 'subscription_ended' ? '#f20' : 
                record?.type === 'upgrade' ? '#87d068' : ''
              }
            >
              {record?.webhook_type}
            </Tag>
          )}
          {record?.webhook_type === 'upgrade' && (
            <div>
              <strong>effective_as_of: {record?.effective_as_of}</strong><br />
              <strong>new_plan:</strong> {JSON.stringify(record?.new_plan)}<br />
              <strong>old_plan:</strong> {JSON.stringify(record?.old_plan)}
            </div>
          )}
          {record?.webhook_type === 'downgrade' && (
            <div>
              <strong>effective_as_of: {record?.effective_as_of}</strong><br />
              <strong>new_plan:</strong> {JSON.stringify(record?.new_plan)}<br />
              <strong>old_plan:</strong> {JSON.stringify(record?.old_plan)}
            </div>
          )}
          <br />
          <strong>subscription_id: {record?.subscription_id}</strong><br />
          <span style={{fontWeight: 'bold', color: 'blue'}}>email: {record?.email}</span><br />
          {JSON.stringify(record)}
        </div>;
      },
      width: "40%",
      filterMultiple: true,
      filters: [
        {
          text: 'sale',
          value: 'sale',
        },
        {
          text: 'upgrade',
          value: 'upgrade',
        },
        {
          text: 'downgrade',
          value: 'downgrade',
        },
        {
          text: 'cancell',
          value: 'cancell',
        },
        {
          text: 'refund',
          value: 'refund',
        },
        {
          text: 'subscription_restarted',
          value: 'subscription_restarted',
        },
      ],
      onFilter: (value, record) => record.webhook_type === value,
    },
    {
      title: "Simulator",
      width: "10%",
      render: (record) => {
        return (
          <div>
            {record?.webhook_type === 'sale' && (
              <Button
              type='link'
              ghost
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm',
                  content: 'Are you sure to subscripton_sale for next cycles?',
                  okText: 'Ok',
                  cancelText: 'Không',
                  onOk: () => {
                    gumroadWebhook({
                      ...record,
                      sale_timestamp: new moment().toISOString(),
                    })
                      .then(async () => {
                        setLoading(true)
                        message.success('Subscripton is successful')
                        const [result] = await Promise.all([
                          gumroadWebhookList(token)
                        ]);
              
                        setItems(result);

                        setLoading(false)
                      })
                      .catch(err => console.log(err))
                  }
                });
              }}
            >subscription_sale</Button>
            )}
            {record?.webhook_type === 'sale' && (
              <Button
              type='link'
              ghost
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm',
                  content: 'Are you sure to subscription_restarted?',
                  okText: 'Ok',
                  cancelText: 'Không',
                  onOk: () => {
                    gumroadWebhookRestarted({
                      ...record,
                      restarted_at: new moment().toISOString(),
                    })
                      .then(async () => {
                        setLoading(true)
                        message.success('subscription_restarted is successful')
                        const [result] = await Promise.all([
                          gumroadWebhookList(token)
                        ]);
              
                        setItems(result);

                        setLoading(false)
                      })
                      .catch(err => console.log(err))
                  }
                });
              }}
            >subscription_restarted</Button>
            )}
            {record?.webhook_type === 'sale' && (
              <Button
              type='link'
              ghost
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm',
                  content: 'Are you sure to subscription_ended for this cycles?',
                  okText: 'Ok',
                  cancelText: 'Không',
                  onOk: () => {
                    gumroadWebhookEnded({
                      ...record,
                      ended_at: new moment().toISOString(),
                    })
                      .then(async () => {
                        setLoading(true)
                        message.success('subscription_ended is successful')
                        const [result] = await Promise.all([
                          gumroadWebhookList(token)
                        ]);
              
                        setItems(result);

                        setLoading(false)
                      })
                      .catch(err => console.log(err))
                  }
                });
              }}
            >subscription_ended</Button>
            )}
          </div>
        );
      }
    }
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Gumroad Webhook</h2>
            </Col>
          </Row>
        </Card>

        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          pagination={{
            defaultPageSize: 20
          }}
        />

      </Layout>
    </Layout>

  )
};

export default SuperUsers;