/* eslint-disable */
import request from "utils/request";
import { firestore } from "utils/firebase";

export const getBillings = async (payload) => {
  return request(`admin/billing`, payload, { method: "GET" })
};

export const getBillingsAdmin = async (payload) => {
  try {
    const fromDate = payload.fromDate;
    const toDate = payload.toDate;

    if (fromDate && toDate && (new Date(fromDate) > new Date(toDate))) {
      return;
    }

    let users = [];
    await firestore.collection("users")
      .get()
      .then(async value => {
        users = await Promise.all(value.docs.map(async doc => {
          return {
            userId: doc.id,
            email: doc.data().email,
            role: doc.data().role,
          };
        }))
      });

    let query = firestore.collection("payments");

    if (fromDate) query = query.where("createdAt", ">=", firestore.Timestamp.fromDate(new Date(fromDate)));
    if (toDate) query = query.where("createdAt", "<=", firestore.Timestamp.fromDate(new Date(`${toDate} 23:59:59`)));

    return await query.orderBy("createdAt", "desc").get()
      .then(value => {
        return {
          data: value.docs.map(item => {
            const user = users.find(user => user.userId === item.data().userId);

            return {
              ...item.data(),
              createdAt: item.data().createdAt?.toDate(),
              user: user
            };
          })
        };
      })
      .catch((err) => {
        console.log(err);
        return;
      });
  }
  catch (err) {
    console.log(err);
    return;
  }
};
