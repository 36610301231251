/* eslint-disable */
import request from "utils/request";

export const systemeContacts = async (payload, token) => {
  return request(`admin/systeme/contacts`, payload, { method: "POST", token })
};

export const systemeCreateContact = async (payload, token) => {
  return request(`admin/systeme/contact`, payload, { method: "POST", token })
};

export const systemeAssignTagToContact = async (payload, token) => {
  return request(`admin/systeme/contact/assign-tag`, payload, { method: "POST", token })
};

export const systemeDeleteContact = async (payload, token) => {
  return request(`admin/systeme/contact/delete`, payload, { method: "POST", token })
};
