import React, { useState } from "react";
import { Form, Input, Button, Row, Col, message, DatePicker, InputNumber } from "antd";
import { enterpriseCreate } from "apis/enterprises";
import moment from "moment-timezone";

const EnterpriseCreateForm = ({ onCancel, onCallback, token, userProfile }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="userUpdateForm"
      onFinish={(values) => {
        setLoading(true);
        let item = {
          name: values?.name,
          email: values.email.toLowerCase(),
          numberOfUsers: values?.numberOfUsers,
          isActive: Boolean(values?.isActive),
          endTime: values?.endTime
        };

        if (userProfile?.data?.role === "reseller") {
          item.createdBy = userProfile?.data?.uid
        }

        enterpriseCreate(item, token)
          .then(() => {
            setLoading(false);
            message.success("Enterprise has been created successfully");
            onCallback();
          })
          .catch((err) => {
            setLoading(false);
            message.error(err.message ?? "Error");
          });
      }}
      initialValues={{
        name: "",
        email: "",
        numberOfUsers: 20,
        endTime: moment()
      }}
    >
      <div className="user-form">
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="email"
              label={"Email"}
              rules={[
                {
                  required: true,
                  message: "Email!",
                },
                {
                  type: "email",
                  message: "Invalid email!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="numberOfUsers"
              label="Number of users"
              rules={[
                {
                  required: true,
                  message: 'Number of users!'
                }
              ]}
            >
              <InputNumber min={1} max={1000} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }}>
            <Form.Item
              label="End date"
              name="endTime"
              rules={[
                {
                  required: true,
                  message: 'End date!'
                }
              ]}
            >
              <DatePicker disabledDate={disabledDate} style={{ width: '100%' }} placeholder="End date" />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: "right" }}>
          <Col className="gutter-row" span={24} style={{ textAlign: "right" }}>
            <Button type="clear" onClick={onCancel}>
              Cancel
            </Button>
            <Button loading={loading} type="primary" htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default EnterpriseCreateForm;
