/* eslint-disable */
import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import moment from 'moment';
import './styles.less';

import { Modal, Button, Tag, Table, Card, Row, Col, message } from 'antd';
import { EditOutlined, PlusCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusSquareOutlined, MinusSquareOutlined, EyeOutlined } from '@ant-design/icons';

import { postList, postDetail, postSectionDelete } from 'apis/posts';
import { categoryList } from 'apis/categories';
import { tagList } from 'apis/tags';
import { authorList } from 'apis/authors';

import PostUpdateForm from '../posts/forms/post-update.form';
import PostSectionCreateForm from '../posts/forms/post-section-create.form';
import PostSectionUpdateForm from '../posts/forms/post-section-update.form';

import useToken from 'hooks/useToken';

const PostPage = () => {
  const { token } = useToken();

  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])
  const [authors, setAuthors] = useState([])

  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isCreateSectionModal, setIsCreateSectionModal] = useState(false)
  const [isUpdateSectionModal, setIsUpdateSectionModal] = useState(false)
  const [isSection, setIsSection] = useState();

  const [expandedPostKey, setExpandedPostKey] = useState([]);
  const [expandedSectionKey, setExpandedSectionKey] = useState([]);

  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState();
  const [postSection, setPostSection] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (token) {
        try {
          setLoading(true)
          const [posts, categories, tags, authors] = await Promise.all([
            postList(token),
            categoryList(token),
            tagList(token),
            authorList(token),
          ]);

          setPosts(posts.filter(item => item.category === process.env.REACT_APP_CATEGORY_TUTORIAL_ID))
          setExpandedPostKey(posts.map(x => x.postId))

          setCategories(categories)
          setTags(tags)
          setAuthors(authors)
          setLoading(false)
        } catch (e) {
          setLoading(false)
        }
      }
    })();
  }, [token]);

  const openUpdateModal = (postId) => {
    const post = posts.find(item => item.postId === postId);
    setPost(post);
    setIsUpdateModal(true);
  };

  const getTagName = (value) => {
    const item = tags.find(item => item.tagId === value);
    return item?.name;
  }

  const columns = [
    {
      title: 'Title',
      width: '50%',
      render: (record) => {
        return (
          <div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <div style={{ color: '#4a90e2', fontSize: 16, fontWeight: 600 }}>{_.get(record, 'title', '')} <a target={'_blank'} href={`${process.env.REACT_APP_ENV === 'staging' ? 'https://staging.crystalsound.ai' : 'https://crystalsound.ai'}/blog/post/${record?.slug}/${record?.postId}`}><EyeOutlined /> Preview</a></div>
                <div>
                  <span style={{ fontWeight: 500, opacity: 0.6 }}>Ngày cập nhật: {moment(record?.updatedAt).format('YYYY-MM-DD')}</span>
                </div>
                <div style={{ marginBottom: 5, display: 'flex', flexDirection: 'row' }}>Tags: {_.get(record, 'tags', []).map(item => (<div style={{ fontWeight: '600', marginLeft: 8 }}>#{getTagName(item)}</div>))}</div>
                {_.get(record, 'isActive', '') === true ?
                  <Tag color="blue">Đang hiển thị</Tag>
                  :
                  <Tag color="red">Đang ẩn</Tag>
                }
                <Button style={{ marginLeft: -15 }} type="link" icon={<EditOutlined />} onClickCapture={() => openUpdateModal(record.postId)}>Cập nhật</Button>
              </div>
              <div style={{ marginTop: 8 }}>
                <img src={_.get(record, 'photo', '')} style={{ width: 140, borderRadius: 5 }} />
              </div>
            </div>

            {_.get(record, 'isLongform', '') === true && (
              <div className='longform'>
                <Row gutter={24} type="flex" style={{ marginTop: 24, marginBottom: 15 }}>
                  <Col span={12}>
                    <div style={{ fontWeight: 'bold', marginTop: 5, marginLeft: 2 }}>Nội dung<Button
                      style={{ marginLeft: 8 }}
                      type="link"
                      icon={!expandedPostKey.includes(record.postId) ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
                      ghost
                      onClick={() => {
                        if (expandedPostKey.includes(record.postId)) {
                          //remove
                          setExpandedPostKey(expandedPostKey.filter(x => x !== record.postId))
                        } else {
                          //add
                          setExpandedPostKey([...expandedPostKey, record.postId])
                        }
                      }}
                    /></div>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Button
                      onClick={() => {
                        setPost()
                        let postId = _.get(record, 'postId', '');
                        setIsSection(true);
                        postDetail(postId, token)
                          .then(result => {
                            setPost(result)
                            setIsCreateSectionModal(true)
                            setLoading(false)
                          })
                          .catch(err => setLoading(false))
                      }}
                      type={'primary'}
                      ghost
                      icon={<PlusCircleOutlined />}
                    >Add New Post Section</Button>
                    <Button
                      onClick={() => {
                        setPost()
                        setIsSection(false);
                        let postId = _.get(record, 'postId', '');

                        postDetail(postId, token)
                          .then(result => {
                            setPost(result)
                            setIsCreateSectionModal(true)
                            setLoading(false)
                          })
                          .catch(err => setLoading(false))
                      }}
                      type={'primary'}
                      icon={<PlusCircleOutlined />}
                      style={{ marginLeft: 15 }}>Add New Post</Button>
                  </Col>
                </Row>
                {expandedPostKey.includes(record.postId) && (
                  _.get(record, 'sections') !== null && _.get(record, 'sections', []).sort((a, b) => {
                    return (a?.no - b?.no)
                  }).filter(x => (x?.sectionId === undefined || x?.sectionId === '')).map(item => {
                    return (
                      <>
                        {/* renderSection */}
                        <Card style={{ marginTop: 10, marginBottom: 10, backgroundColor: '#EDEFF0' }}>
                          <Row gutter={24} type="flex">
                            <Col span={12}>
                              <div style={{ fontWeight: '600', marginTop: 5 }}>{item?.no.toString().toUpperCase()}. {item?.title.toUpperCase()} {item?.isActive === true ? <Tag style={{ marginLeft: 5 }}>Active</Tag> : <Tag color={'red'} style={{ marginLeft: 5 }}>In-active</Tag>}
                                {_.get(record, 'sections', []).filter(x => x?.sectionId === item?.postSectionId).length !== 0 && (
                                  <Button
                                    style={{ marginLeft: 8 }}
                                    type="link"
                                    icon={!expandedSectionKey.includes(item.postSectionId) ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
                                    ghost
                                    onClick={() => {
                                      if (expandedSectionKey.includes(item.postSectionId)) {
                                        //remove
                                        setExpandedSectionKey(expandedSectionKey.filter(x => x !== item.postSectionId))
                                      } else {
                                        //add
                                        setExpandedSectionKey([...expandedSectionKey, item.postSectionId])
                                      }
                                    }}
                                  />
                                )}

                              </div>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                              <Button
                                style={{ marginLeft: 8 }}
                                type="link"
                                icon={<EditOutlined />}
                                ghost
                                onClick={() => {
                                  setPost()
                                  let postId = _.get(record, 'postId', '');

                                  if (item?.sectionId) {
                                    setIsSection(false)
                                  }

                                  postDetail(postId, token)
                                    .then(result => {
                                      setPost(result)
                                      setPostSection(item)
                                      setIsUpdateSectionModal(true)
                                      setLoading(false)
                                    })
                                    .catch(err => setLoading(false))
                                }}
                              />
                            </Col>
                          </Row>
                        </Card>

                        {/* renderPost */}
                        {expandedSectionKey.includes(item.postSectionId) && (
                          _.get(record, 'sections', []).sort((a, b) => {
                            return (a?.no - b?.no)
                          }).filter(x => x?.sectionId === item?.postSectionId).map(item => {
                            return (
                              <Card style={{ marginTop: 10, marginBottom: 10, paddingLeft: 40 }}>
                                <Row gutter={24} type="flex">
                                  <Col span={12}>
                                    <div style={{ fontWeight: '600', marginTop: 5 }}>{item?.no}. {item?.title} {item?.isActive === true ? <Tag style={{ marginLeft: 5 }}>Active</Tag> : <Tag color={'red'} style={{ marginLeft: 5 }}>In-active</Tag>}</div>
                                  </Col>
                                  <Col span={12} style={{ textAlign: 'right' }}>
                                    <Button
                                      style={{ marginLeft: 8 }}
                                      type="link"
                                      icon={<EditOutlined />}
                                      ghost
                                      onClick={() => {
                                        setPost()
                                        let postId = _.get(record, 'postId', '');

                                        if (item?.sectionId) {
                                          setIsSection(false)
                                        }

                                        postDetail(postId, token)
                                          .then(result => {
                                            setPost(result)
                                            setPostSection(item)
                                            setIsUpdateSectionModal(true)
                                            setLoading(false)
                                          })
                                          .catch(err => setLoading(false))
                                      }}
                                    />
                                    <Button
                                      style={{ marginLeft: 8 }}
                                      type="link"
                                      icon={<DeleteOutlined />}
                                      ghost
                                      danger
                                      onClick={() => {
                                        Modal.confirm({
                                          title: 'Confirm',
                                          icon: <ExclamationCircleOutlined />,
                                          content: 'Bạn có chắc chắn muốn xoá?',
                                          okText: 'Ok',
                                          cancelText: 'Không',
                                          onOk: () => {
                                            let postId = _.get(record, 'postId', '');
                                            postSectionDelete({ postId, postSectionId: item.postSectionId }, token)
                                              .then(async (result) => {
                                                setLoading(true)
                                                message.success('Section đã được xoá thành công')
                                                const [posts] = await Promise.all([
                                                  postList(token),
                                                ]);
                                                setPosts(posts);
                                                setLoading(false)
                                              })
                                              .catch(err => console.log(err))
                                          }
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Card>
                            )
                          })
                        )}
                      </>
                    )
                  })
                )}
              </div>
            )}
          </div>
        )
      }
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 18 }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
          <Col className="gutter-row" span={12}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <h2>Tutorial</h2>
            </div>
          </Col>
        </Row>
      </Card>

      <Table
        showHeader={false}
        loading={loading}
        bordered
        columns={columns}
        dataSource={posts}
      />

      <Modal
        visible={isUpdateModal}
        title="Post / Update"
        onCancel={() => {
          Modal.confirm({
            title: 'Bạn có chắc chắn muốn thoát ?',
            icon: null,
            onOk() {
              setIsUpdateModal(false)
            },
            okText: 'Yes',
            onCancel() { },
            cancelText: 'No',
          });
        }}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <PostUpdateForm
          token={token}
          posts={posts}
          categories={categories}
          tags={tags}
          post={post}
          authors={authors}
          onCancel={() => setIsUpdateModal(false)}
          onCallback={async () => {
            const [posts] = await Promise.all([
              postList(token),
            ]);
            setPosts(posts.filter(item => item.category === process.env.REACT_APP_CATEGORY_TUTORIAL_ID))
          }}
        />
      </Modal>
      <Modal
        visible={isCreateSectionModal}
        title={`Longform / Create ${isSection === true ? 'Section' : 'Post'}`}
        onCancel={() => {
          Modal.confirm({
            title: 'Bạn có chắc chắn muốn thoát ?',
            icon: null,
            onOk() {
              setIsCreateSectionModal(false)
            },
            okText: 'Yes',
            onCancel() { },
            cancelText: 'No',
          });
        }}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <PostSectionCreateForm
          isSection={isSection}
          token={token}
          post={post}
          onCancel={() => setIsCreateSectionModal(false)}
          onCallback={async () => {
            const [posts] = await Promise.all([
              postList(token),
            ]);
            setPosts(posts.filter(item => item.category === process.env.REACT_APP_CATEGORY_TUTORIAL_ID))
          }}
        />
      </Modal>
      <Modal
        visible={isUpdateSectionModal}
        title={`Longform / Update ${isSection === true ? 'Section' : 'Post'}`}
        onCancel={() => {
          Modal.confirm({
            title: 'Bạn có chắc chắn muốn thoát ?',
            icon: null,
            onOk() {
              setIsUpdateSectionModal(false)
            },
            okText: 'Yes',
            onCancel() { },
            cancelText: 'No',
          });
        }}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <PostSectionUpdateForm
          isSection={isSection}
          token={token}
          post={post}
          postSection={postSection}
          onCancel={() => setIsUpdateSectionModal(false)}
          onCallback={async () => {
            const [posts] = await Promise.all([
              postList(token),
            ]);
            setPosts(posts.filter(item => item.category === process.env.REACT_APP_CATEGORY_TUTORIAL_ID))
          }}
        />
      </Modal>
    </>
  )
};

export default PostPage;