import {db} from 'utils/firebase'
import { collection, query, doc, orderBy, getDocs, getDoc, addDoc, updateDoc, serverTimestamp  } from "firebase/firestore";

export const categoryList = async () => {
  const q = query(collection(db, "categories"), orderBy('no', 'asc'));
  const querySnapshot = await getDocs(q);

  let cats = [];
  querySnapshot.forEach((doc) => {
    cats.push({
      categoryId: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt.toDate(),
    });
  });

  return cats;
};

export const categoryDetail = async (payload) => {
  const docRef = doc(db, "categories", payload?.categoryId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      ...docSnap.data(), 
      categoryId: docSnap.id,
    }
  } else {
    return undefined
  }
};

export const categoryCreate = async (payload) => {
  try {
    const docRef = await addDoc(collection(db, "categories"), {
      ...payload,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
    return {
      status: 'success',
      id: docRef.id
    };
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};

export const categoryUpdate = async (payload) => {
  try {
    const categoryId = payload?.categoryId;
    delete payload.categoryId;

    await updateDoc(doc(db, "categories", categoryId), {
      ...payload,
      updatedAt: serverTimestamp(),
    });
    return {
      status: 'success',
      id: categoryId
    };
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};


