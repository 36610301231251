import React, { useState, useEffect, useRef } from 'react';

import { Layout, Button, Table, Modal, Row, Col, Input, Card, Space, Tag, message } from 'antd';
import { SearchOutlined, PlusOutlined, EditOutlined, DeleteFilled, RetweetOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import moment from 'moment';
import useToken from 'hooks/useToken';
import useAuth from 'hooks/useAuth';
import { enterpriseList, enterpriseDowngrade } from 'apis/enterprises';

import UpdateForm from './forms/enterprise-update.form';
import CreateForm from "./forms/enterprise-create.form";
import ExtendForm from "./forms/enterprise-extend.form";

import './styles.less';

const Enterprise = () => {
  const { token } = useToken();
  const { user: userProfile } = useAuth();
  const [loading, setLoading] = useState([]);

  const searchInputEl = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isExtendModal, setIsExtendModal] = useState(false);
  const [items, setItems] = useState([]);
  const [item, setItem] = useState();

  const [expandedKey, setExpandedKey] = useState([]);
  const onExpand = (_, { enterpriseId }) => expandedKey.includes(enterpriseId) ? setExpandedKey(prev => prev.filter(item => item !== enterpriseId)) : setExpandedKey(prev => [...prev, enterpriseId]);

  useEffect(() => {
    (async () => {
      if (token && userProfile?.role) {
        try {
          setLoading(true);

          let payload = {}
          if (userProfile?.role === "reseller") {
            payload = {
              createdBy: userProfile?.id
            }
          }
          const [result] = await Promise.all([
            enterpriseList(payload, token)
          ]);
          setItems(result.filter(x => x.email === x.enterprise?.activation_email).map(item => {
            return {
              ...item,
              users: result.filter(y => item.email === y.enterprise?.activation_email && y.email !== y.enterprise?.activation_email)
            }
          }));

          setLoading(false);
        } catch (e) {
          console.log('error: ', e)
          setLoading(false);
        }
      }
    })();
  }, [token, userProfile?.role]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ""
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div>
              {(dataIndex === "email" && text?.appsumo !== undefined) && (
                <span style={{ fontWeight: "bold", marginRight: 5, color: "orange" }}>[AppSumo]</span>
              )}
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? text[dataIndex].toString() : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const columns = [
    {
      title: "Created At",
      align: "center",
      dataIndex: "createdAt",
      width: "15%",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        );
      }
    },
    {
      title: "Name",
      width: "25%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      width: "25%",
      ...getColumnSearchProps("email"),
    },
    {
      title: 'Number Of Users',
      align: 'center',
      dataIndex: 'enterprise',
      width: '15%',
      render: (text, record, index) => {
        return (
          <div style={{ fontWeight: 'bold' }}>{record.enterprise?.numberOfUsers}</div>
        );
      }
    },
    {
      title: 'Activated Users',
      align: 'center',
      dataIndex: 'users',
      width: '15%',
      render: (text, record, index) => {
        return (
          <div style={{ fontWeight: 'bold' }}>{record.users?.filter(x => x.role === 'pre')?.length}</div>
        );
      }
    },
    {
      title: 'Role',
      align: 'center',
      dataIndex: 'role',
      render: (record) => {
        if (record === 'free') {
          return <Tag>{record.toUpperCase()}</Tag>;
        } else {
          return <Tag color="green">{record.toUpperCase()}</Tag>;
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Free',
          value: 'free',
        },
        {
          text: 'Trial',
          value: 'trial',
        },
        {
          text: 'Pre',
          value: 'pre',
        },
      ],
      onFilter: (value, record) => record.role === value,
    },
    {
      title: 'EndTime',
      align: 'center',
      dataIndex: 'endTime',
      width: '10%',
      sorter: (a, b) => moment(a.endTime).unix() - moment(b.endTime).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.endTime).format('YYYY-MM-DD')}</div>
          </div>
        );
      }
    },
    // {
    //   title: "Active",
    //   dataIndex: "isActive",
    //   render: (record) => {
    //     if (record) {
    //       return <Tag color="green">Active</Tag>;
    //     } else {
    //       return <Tag color={"red"}>Inactive</Tag>;
    //     }
    //   },
    //   width: "15%",
    //   align: "center",
    //   filterMultiple: true,
    //   filters: [
    //     {
    //       text: "Active",
    //       value: true,
    //     },
    //     {
    //       text: "Inactive",
    //       value: false,
    //     },
    //   ],
    //   onFilter: (value, record) => record.isActive === value,
    // },
    {
      title: "Action",
      align: "center",
      width: "10%",
      render: (record) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="link"
              icon={<RetweetOutlined />}
              onClickCapture={() => {
                setItem(record);
                setIsExtendModal(true);
              }}
            >
              Extend
            </Button>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClickCapture={() => {
                setItem(record);
                setIsUpdateModal(true);
              }}
            >
              Edit
            </Button>
            <Button
              type='link'
              icon={<DeleteFilled />}
              ghost
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm',
                  icon: <DeleteFilled />,
                  content: 'Are you sure to delete?',
                  okText: 'Ok',
                  cancelText: 'Không',
                  onOk: () => {
                    let enterpriseId = record?.enterpriseId;
                    let payload = {
                      enterpriseId,
                    }

                    enterpriseDowngrade(payload)
                      .then(async () => {
                        setLoading(true)
                        message.success('Delete is successful')

                        let payload = {}
                        if (userProfile?.role === "reseller") {
                          payload = {
                            createdBy: userProfile?.id
                          }
                        }
                        const [result] = await Promise.all([
                          enterpriseList(payload, token)
                        ]);

                        setItems(result.filter(x => x.email === x.enterprise?.activation_email).map(item => {
                          return {
                            ...item,
                            users: result.filter(y => item.email === y.enterprise?.activation_email && y.email !== y.enterprise?.activation_email)
                          }
                        }));

                        setLoading(false)
                      })
                      .catch(err => console.log(err))
                  }
                });
              }}
            >Delete</Button>
          </div>
        );
      }
    }
  ];

  const columnsUser = [
    {
      title: "Created At",
      align: "center",
      dataIndex: "createdAt",
      width: "10%",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        );
      }
    },
    {
      title: "Email",
      width: "35%",
      ...getColumnSearchProps("email"),
    },
    {
      title: 'Role',
      align: 'center',
      dataIndex: 'role',
      render: (record) => {
        if (record === 'free') {
          return <Tag>{record.toUpperCase()}</Tag>;
        } else {
          return <Tag color="green">{record.toUpperCase()}</Tag>;
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Free',
          value: 'free',
        },
        {
          text: 'Trial',
          value: 'trial',
        },
        {
          text: 'Pre',
          value: 'pre',
        },
      ],
      onFilter: (value, record) => record.role === value,
    },
    {
      title: 'EndTime',
      align: 'center',
      dataIndex: 'endTime',
      width: '10%',
      sorter: (a, b) => moment(a.endTime).unix() - moment(b.endTime).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        console.log('record: ', record)
        return (
          <div>
            <div>{moment(record?.endTime).format('YYYY-MM-DD')}</div>
          </div>
        );
      }
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Enterprise Users</h2>
            </Col>
            <Col
              span={12}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                onClick={() => setIsCreateModal(true)}
                icon={<PlusOutlined />}
              >
                Create enterprise user
              </Button>
              <Button
                style={{ float: 'right' }}
                type={'link'}
                href="/v1/enterprise/downgrade"
              >
                Downgrade
              </Button>
            </Col>
          </Row>
        </Card>

        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={items}
          pagination={{
            defaultPageSize: 20
          }}
          rowKey={(row) => row.enterpriseId}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                loading={loading}
                bordered
                columns={columnsUser}
                dataSource={record.users}
                pagination={false}
              />
            ),
            onExpand: onExpand,
            expandedRowKeys: [...expandedKey]
          }}
        />

        <Modal
          visible={isCreateModal}
          title={"Create Enterprise User"}
          onCancel={() => {
            setIsCreateModal(false);
          }}
          destroyOnClose={true}
          width={570}
          centered
          footer={null}
        >
          <CreateForm
            userProfile={userProfile}
            onCancel={() => setIsCreateModal(false)}
            onCallback={async () => {
              let payload = {}
              if (userProfile?.role === "reseller") {
                payload = {
                  createdBy: userProfile?.id
                }
              }
              const [result] = await Promise.all([enterpriseList(payload, token)]);

              setItems(result.filter(x => x.email === x.enterprise?.activation_email).map(item => {
                return {
                  ...item,
                  users: result.filter(y => item.email === y.enterprise?.activation_email && y.email !== y.enterprise?.activation_email)
                }
              }));

              setIsCreateModal(false);
            }}
            token={token}
          />
        </Modal>

        <Modal
          visible={isUpdateModal}
          title={"Update Enterprise User"}
          onCancel={() => {
            setIsUpdateModal(false);
          }}
          destroyOnClose={true}
          width={570}
          centered
          footer={null}
        >
          <UpdateForm
            enterprise={item}
            onCancel={() => setIsUpdateModal(false)}
            onCallback={async () => {
              let payload = {}
              if (userProfile?.role === "reseller") {
                payload = {
                  createdBy: userProfile?.id
                }
              }
              const [result] = await Promise.all([enterpriseList(payload, token)]);

              setItems(result.filter(x => x.email === x.enterprise?.activation_email).map(item => {
                return {
                  ...item,
                  users: result.filter(y => item.email === y.enterprise?.activation_email && y.email !== y.enterprise?.activation_email)
                }
              }));

              setIsUpdateModal(false);
            }}
            token={token}
          />
        </Modal>

        <Modal
          visible={isExtendModal}
          title={"Extend Enterprise User"}
          onCancel={() => {
            setIsExtendModal(false);
          }}
          destroyOnClose={true}
          width={570}
          centered
          footer={null}
        >
          <ExtendForm
            enterprise={item}
            onCancel={() => setIsExtendModal(false)}
            onCallback={async () => {
              debugger
              let payload = {}
              if (userProfile?.role === "reseller") {
                payload = {
                  createdBy: userProfile?.id
                }
              }
              const [result] = await Promise.all([enterpriseList(payload, token)]);

              setItems(result.filter(x => x.email === x.enterprise?.activation_email).map(item => {
                return {
                  ...item,
                  users: result.filter(y => item.email === y.enterprise?.activation_email && y.email !== y.enterprise?.activation_email)
                }
              }));

              setIsExtendModal(false);
            }}
            token={token}
          />
        </Modal>
      </Layout>
    </Layout>

  )
};

export default Enterprise;