/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';

import { Layout, Button, Table, DatePicker, Modal, Row, Col, Input, Card, Space, Tag, Radio, Statistic, message, Select } from 'antd';
import { SearchOutlined, DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import useAuth from 'hooks/useAuth';
import { systemeContacts, systemeDeleteContact } from 'apis/systeme';
import moment from 'moment';

import './styles.less';
const Systeme = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState([]);

  const searchInputEl = useRef(null);
  const [, setUsers] = useState([]);

  const [usersFilter, setUsersFilter] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');

  const [tags, setTags] = useState();
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        //setLoading(true)
        if (user) {
          //fetch data
          setLoading(true)
          let payload = {
            tags,
          };
          const [result] = await Promise.all([
            systemeContacts(payload),
          ]);

          setUsers(result?.items.map(item => {
            return {
              ...item,
              key: item.id,
            }
          }));
          setUsersFilter(result?.items.map((item, index) => {
            return {
              ...item,
              key: item.id,
              no: index + 1,
            }
          }));

          setHasMore(result?.hasMore)
          setLoading(false)
        }
      } catch (e) {
        setLoading(false)
      }
    })();
  }, [user])

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputEl}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      const [firstValue, ...restValue] = value;

      if (dataIndex === "usernameReferral" && firstValue === "!") {
        return record[dataIndex]
          ? !record[dataIndex].toString().toLowerCase().includes(restValue.join("").toLowerCase())
          : true;
      }

      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '';
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <div>
              {/* {(dataIndex === 'email' && text?.appsumo !== undefined) && (
                <span style={{ fontWeight: 'bold', marginRight: 5, color: 'orange' }}>[AppSumo]</span>
              )} */}
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? (dataIndex === "source" ? `${text[dataIndex].charAt(0).toUpperCase()}${text[dataIndex].slice(1)}`.toString() : text[dataIndex].toString()) : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const start = () => {
    debugger
    Modal.confirm({
      title: 'Confirm',
      content: 'Are you sure to delete this contacts?',
      okText: 'Ok',
      cancelText: 'Không',
      onOk: async () => {
        await Promise.all(selectedRowKeys.map(async (item) => {
          let payload = {
            contactId: item
          }
          await systemeDeleteContact(payload)
        }))

        message.success('This contact has benn deleted successfully')
        setUsersFilter(usersFilter.filter(x => !selectedRowKeys.includes(x?.id)))
      }
      
    });
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const columns = [
    {
      title: 'No.',
      align: 'center',
      dataIndex: 'no',
      width: '6%',
    },
    {
      title: 'Registered at',
      align: 'center',
      dataIndex: 'registeredAt',
      width: '20%',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.registeredAt).format('YYYY-MM-DD hh:mm')}</div>
          </div>
        );
      }
    },
    {
      title: 'ID',
      width: '10%',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Email',
      width: '40%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Tags',
      align: 'center',
      render: (record) => {
        return (
          <div>
            {JSON.stringify(record.tags.map(x => x.name))}
          </div>
        )
      },
      width: '30%',
    },
    {
      width: '20%',
      key: "action",
      render: (record) =>
        <div>
          {(user?.role === "super") && (
            <>
              <Button
                style={{ color: 'red' }}
                type='link'
                ghost
                onClick={() => {
                  Modal.confirm({
                    title: 'Confirm',
                    content: 'Are you sure to delete this contact?',
                    okText: 'Ok',
                    cancelText: 'Không',
                    onOk: () => {
                      let payload = {
                        contactId: record?.id
                      }
                      systemeDeleteContact(payload)
                        .then(async () => {
                          message.success('This contact has benn deleted successfully')
                          setLoading(true)
                          setUsersFilter(usersFilter.filter(x => x.id !== record?.id))
                          setLoading(false)
                        })
                        .catch(err => console.log(err))
                    }
                  });
                }}
              >Delete</Button>
            </>
          )
          }
        </div>
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Email Marketing (systeme.io)</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Select
                placeholder="Tag"
                style={{ width: 300 }}
                value={tags}
                onChange={(value) => {
                  setTags(value);
                }}
              >
                <Select.Option value="add_to_contact">#add_to_contact</Select.Option>
                <Select.Option value="not_installed_after_1day">#not_installed_after_1day</Select.Option>
                <Select.Option value="not_usaged_after_3days">#not_usaged_after_3days</Select.Option>
                <Select.Option value="not_payment_after_6days">#not_payment_after_6days</Select.Option>
                <Select.Option value="has_been_downgraded">#has_been_downgraded</Select.Option>
                <Select.Option value="added_to_contact_after_14days">#added_to_contact_after_14days</Select.Option>
                <Select.Option value="subscription_ended">#subscription_ended</Select.Option>
                <Select.Option value="no_usaged_14days">#no_usaged_14days</Select.Option>
                <Select.Option value="user_deleted">#user_deleted</Select.Option>
                <Select.Option value="has_usaged_300hours">#has_usaged_300hours</Select.Option>
                <Select.Option value="">All</Select.Option>
              </Select>

              <Button
                type="primary"
                style={{ marginLeft: 24 }}
                icon={<SearchOutlined />}
                onClick={() => {
                  setLoading(true);
                  let payload = {
                    tags,
                  };

                  systemeContacts(payload)
                    .then(result => {
                      setUsersFilter(result?.items.map((item, index) => {
                        return {
                          ...item,
                          key: item.id,
                          no: index + 1,
                        }
                      }));

                      setHasMore(result?.hasMore);
                      setLoading(false);
                    })
                    .catch(err => {
                      setUsersFilter([])
                      setLoading(false);
                    })
                }}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Card>

        <div
          style={{
            marginBottom: 16,
          }}
        >
          <Button type="primary" onClick={start} disabled={!hasSelected} loading={loading}>
            Delete
          </Button>
          <span
            style={{
              marginLeft: 8,
            }}
          >
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
        </div>

        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={usersFilter}
          rowSelection={{
            type: 'checkbok',
            ...rowSelection,
          }}
          pagination={{
            defaultPageSize: 100
          }}
        />
        {hasMore && (
          <Button 
            type='primary'
            onClick={(() => {
              setLoading(true);
              let payload = {
                tags,
                startingAfter: usersFilter[usersFilter?.length - 1]?.id,
              };

              systemeContacts(payload)
                .then(result => {
                  let items = [
                    ...usersFilter,
                    ...result?.items.map(item => {
                      return {
                        ...item,
                        key: item.id,
                      }
                    })
                  ]

                  setUsersFilter(items.map((item, index) => {
                    return {
                      no: index + 1,
                      ...item,
                    }
                  }));

                  setHasMore(result?.hasMore);
                  setLoading(false);
                })
                .catch(err => {
                  setLoading(false);
                })
            })}
            style={{marginTop: 10, marginBottom: 10}}
          >Loadmore</Button>
        )}
      </Layout>
    </Layout>

  )
};

export default Systeme;