import { db } from 'utils/firebase';
import { collection, query, doc, orderBy, getDocs, getDoc, addDoc, updateDoc, serverTimestamp  } from "firebase/firestore";

export const authorList = async () => {
  const q = query(collection(db, "authors"), orderBy('createdAt', 'desc'));
  const querySnapshot = await getDocs(q);

  let authors = [];
  querySnapshot.forEach((doc) => {
    authors.push({
      authorId: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt !== undefined ? doc.data().createdAt.toDate() : undefined,
    });
  });

  return authors;
};

export const authorDetail = async (payload) => {
  const docRef = doc(db, "authors", payload?.authorId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      ...docSnap.data(), 
      authorId: docSnap.id,
    }
  } else {
    return undefined
  }
};

export const authorCreate = async (payload) => {
  try {
    const docRef = await addDoc(collection(db, "authors"), {
      ...payload,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
    return {
      status: 'success',
      id: docRef.id
    };
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};

export const authorUpdate = async (payload) => {
  try {
    const authorId = payload?.authorId;
    delete payload.authorId;
    await updateDoc(doc(db, "authors", authorId), {
      ...payload,
      updatedAt: serverTimestamp(),
    });
    return {
      status: 'success',
      id: authorId
    };
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};
