import React, { useState } from "react";
import { Form, Input, Button, Row, Col, message, DatePicker, InputNumber } from "antd";
import { enterpriseExtend } from "apis/enterprises";
import moment from 'moment';

const EnterpriseExtendForm = ({ enterprise = {}, onCancel, onCallback, token }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const disabledDate = (current) => {
    // Can not select days before today and today
    return (current && current < moment().endOf('day')) || current < moment(enterprise?.endTime).endOf('day');
  };
  
  return (
    <Form
      form={form}
      layout="vertical"
      name="superUserUpdateForm"
      onFinish={(values) => {
        debugger
        setLoading(true);
        let item = {
          enterpriseId: enterprise.enterpriseId,
          email: enterprise.email,
          name: values?.name,
          numberOfUsers: values?.numberOfUsers,
          numberOfUsersCurrent: enterprise?.numberOfUsers,
          endTime: values?.endTime,
          extendTime: values?.extendTime,
        };

        enterpriseExtend(item, token)
          .then(() => {
            setLoading(false);
            message.success("Enterprise has been updated successfully");
            onCallback();
          })
          .catch((err) => {
            debugger
            setLoading(false);
            message.error(err.message ?? "Error");
          });
      }}
      initialValues={{
        name: enterprise?.name,
        email: enterprise?.email,
        numberOfUsers: enterprise?.enterprise?.numberOfUsers,
        endTime: moment(enterprise?.endTime),
        extendTime: moment(enterprise?.endTime),
      }}
    >
      <div className="user-form">
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Name!",
                },
              ]}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="email"
              label={"Email"}
              rules={[
                {
                  required: true,
                  message: "Email!",
                },
                {
                  type: "email",
                  message: "Invalid email!",
                },
              ]}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="numberOfUsers"
              label="Number of users"
              rules={[
                {
                  required: true,
                  message: 'Number of users!'
                }
              ]}
            >
              {/* <InputNumber min={enterprise?.role === 'free' ? 20 : enterprise.numberOfUsers < 20 ? 20: enterprise.numberOfUsers} max={1000} style={{ width: '100%' }} /> */}
              <InputNumber min={1} max={1000} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }}>
            <Form.Item
              label="End date"
              name="endTime"
              rules={[
                {
                  required: true,
                  message: 'End date!'
                }
              ]}
            >
              <DatePicker 
                disabled={true} 
                style={{ width: '100%' }} 
                placeholder="End date" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }}>
            <Form.Item
              label="Extend date"
              name="extendTime"
              rules={[
                {
                  required: true,
                  message: 'Extend date!'
                }
              ]}
            >
              <DatePicker 
                disabledDate={disabledDate}
                style={{ width: '100%' }} 
                placeholder="Extend date" />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: "right" }}>
          <Col className="gutter-row" span={24} style={{ textAlign: "right" }}>
            <Button type="clear" onClick={onCancel}>
              Cancel
            </Button>
            <Button loading={loading} type="primary" htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default EnterpriseExtendForm;
