import React, { useState, useEffect } from 'react';
import './styles.less';

import { Modal, Button, Table, Card, Row, Col, Tag } from 'antd';
import { PlusOutlined, StarFilled } from '@ant-design/icons';

import { tagList } from 'apis/tags';

import TagCreateForm from './forms/tag-create.form';
import TagUpdateForm from './forms/tag-update.form';

import moment from 'moment';

const TagPage = () => {
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [tag, setTag] = useState({});
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const [tags] = await Promise.all([
          tagList(),
        ]);

        setTags(tags)

        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    })();
  }, []);

  const openUpdateModal = (tagId) => {
    const tag = tags.find(item => item.tagId === tagId);
    setTag(tag);
    setIsUpdateModal(true);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {
      title: 'Tag',
      width: '20%',
      render: (record) => {
        return (
          <div>
            {record.isPopular === true && (<StarFilled style={{ marginRight: 8, color: '#4a90e2' }} />)}
            {record.name}
          </div>
        )
      }
    },
    {
      title: 'Updated At',
      align: 'center',
      dataIndex: 'updatedAt',
      width: '7%',
      render: (text, record, index) => {
        return (
          `${moment(record.createdAt).format('YYYY-MM-DD')}`
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      align: 'center',
      width: '4%',
      render: (record) => {
        if (record === true)
          return (
            <Tag>Active</Tag>
          )
        else {
          return (
            <Tag color={'red'}>In-active</Tag>
          )
        }
      }
    },
    {
      title: '',
      align: 'center',
      width: '6%',
      render: (record) => {
        return (
          <Button type="primary" onClickCapture={() => openUpdateModal(record.tagId)}>Sửa</Button>
        );
      }
    }
  ];

  return (
    <>
      <Card style={{ marginBottom: 18 }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
          <Col className="gutter-row" span={12}>
            <h2>Tag</h2>
          </Col>
          <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={() => setIsCreateModal(true)} icon={<PlusOutlined />}>Thêm mới</Button>
          </Col>
        </Row>
      </Card>
      <Table
        loading={loading}
        bordered
        columns={columns}
        dataSource={tags}
        onChange={(pagination, filters, sorter) => {
          setCurrentPage(pagination.current);
        }}
        pagination={{
          total: tags.lenght,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
          pageSize: 20,
          current: currentPage
        }}
      />
      <Modal
        visible={isCreateModal}
        title="Tag / Thêm mới"
        onCancel={() => setIsCreateModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <TagCreateForm
          onCancel={() => setIsCreateModal(false)}
          onCallback={async () => {
            setLoading(true)

            const [tags] = await Promise.all([
              tagList(),
            ]);
            setTags(tags)
            setLoading(false)

            setIsCreateModal(false)
          }}
        />
      </Modal>
      <Modal
        visible={isUpdateModal}
        title="Tag / Sửa"
        onCancel={() => setIsUpdateModal(false)}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <TagUpdateForm
          tag={tag}
          onCancel={() => setIsUpdateModal(false)}
          onCallback={async () => {
            debugger
            setLoading(true)

            const [tags] = await Promise.all([
              tagList(),
            ]);
            setTags(tags)
            setLoading(false)

            setIsUpdateModal(false)
          }}
        />
      </Modal>
    </>
  )
};

export default TagPage;