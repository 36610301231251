import React from 'react';
import { Layout, Menu } from 'antd';
import { Outlet, useNavigate } from 'react-router';
import './styles.less';

const { Sider } = Layout;

const SidebarLayout = ({ role }) => {
  let navigate = useNavigate();
  const defaultKey = (role === "super") ? "1" : "2";

  return (
    <Layout className={`sidebar-page`}>
      <Sider width={200} className="sider-left">
        <Menu
          mode="inline"
          defaultSelectedKeys={[defaultKey]}
          style={{ height: '100%', borderRight: 0, marginTop: 30 }}
        >
          <Menu.Item key="1" onClick={() => navigate(`/v1/master/versions`)}>Version</Menu.Item>
          <Menu.Item key="2" onClick={() => navigate(`/v1/master/categories`)}>Category</Menu.Item>
          <Menu.Item key="3" onClick={() => navigate(`/v1/master/tags`)}>Tag</Menu.Item>
          <Menu.Item key="4" onClick={() => navigate(`/v1/master/authors`)}>Author</Menu.Item>
          <Menu.Item key="5" onClick={() => navigate(`/v1/master/announcements`)}>Announcement</Menu.Item>
          <Menu.Item key="6" onClick={() => navigate(`/v1/master/tutorials`)}>Tutorial</Menu.Item>
          <Menu.Item key="7" onClick={() => navigate(`/v1/master/superusers`)}>Super User</Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{ padding: '0 24px 24px' }}>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default SidebarLayout;