/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';

import _ from 'lodash';
import moment from 'moment';
import './styles.less';

import { Layout, Modal, Button, Tag, Table, Card, Row, Col, Tabs, Input, message, Checkbox, Select } from 'antd';
import { PlusOutlined, EditOutlined, PlusCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusSquareOutlined, MinusSquareOutlined, EyeOutlined } from '@ant-design/icons';

import { postList, postDetail, postSectionDelete } from 'apis/posts';
import { categoryList } from 'apis/categories';
import { tagList } from 'apis/tags';
import { authorList } from 'apis/authors';

import PostCreateForm from './forms/post-create.form';
import PostUpdateForm from './forms/post-update.form';
import PostSectionCreateForm from './forms/post-section-create.form';
import PostSectionUpdateForm from './forms/post-section-update.form';

import useAuth from 'hooks/useAuth';

const { Search } = Input;
const { TabPane } = Tabs;

const PostPage = () => {
  const { user } = useAuth();

  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([])
  const [authors, setAuthors] = useState([])

  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [isCreateSectionModal, setIsCreateSectionModal] = useState(false)
  const [isUpdateSectionModal, setIsUpdateSectionModal] = useState(false)
  const [isSection, setIsSection] = useState();

  const [expandedPostKey, setExpandedPostKey] = useState([]);
  const [expandedSectionKey, setExpandedSectionKey] = useState([]);

  const [isShowCategory, setIsShowCategory] = useState(false)

  const [posts, setPosts] = useState([]);
  const [postsFilter, setPostsFilter] = useState([]);
  const [post, setPost] = useState();
  const [postSection, setPostSection] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (user) {
        try {
          setLoading(true)
          const [posts, categories, tags, authors] = await Promise.all([
            postList(),
            categoryList(),
            tagList(),
            authorList(),
          ]);

          setPosts(posts)
          setPostsFilter(posts)
          setExpandedPostKey(posts.map(x => x.postId))

          setCategories(categories.filter(item => item.categoryId !== process.env.CATEGORY_TUTORIAL_ID))
          setTags(tags)
          setAuthors(authors)
          setLoading(false)
        } catch (e) {
          console.log(e)
          setLoading(false)
        }
      }
    })();
  }, [user]);

  const openUpdateModal = (postId) => {
    const post = posts.find(item => item.postId === postId);
    console.log("^^^ Cập nhật post mới:", post);
    setPost(post);
    setIsUpdateModal(true);
  };

  const getTagName = (value) => {
    const item = tags.find(item => item.tagId === value);
    return item?.name;
  }

  const getCategoryName = (value) => {
    const item = categories.find(item => item.categoryId === value);
    return item?.name;
  }

  const columns = [
    {
      title: 'Title',
      width: '50%',
      render: (record) => {
        return (
          <div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <div style={{ color: '#4a90e2', fontSize: 16, fontWeight: 600 }}>{_.get(record, 'no', '')}. {_.get(record, 'title', '')} <a target={'_blank'} href={`${process.env.REACT_APP_ENV === 'staging' ? 'https://staging.crystalsound.ai' : 'https://crystalsound.ai'}/blog/post/${record?.slug}`}><EyeOutlined /> Preview</a></div>
                <div>
                  <span style={{ fontWeight: 500, marginRight: 20, color: '#4a90e2' }}>Ngày đăng: {moment(record?.publishDate).format('YYYY-MM-DD')}</span>
                  <span style={{ fontWeight: 500, opacity: 0.6 }}>Ngày cập nhật: {record?.updatedAt ? moment(record?.updatedAt?.toDate()).format('YYYY-MM-DD'): "N/A"}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <span style={{ fontWeight: '600' }}>{getCategoryName(_.get(record, 'categoryParent', ''))} / {getCategoryName(_.get(record, 'category', ''))}</span>
                </div>
                <div style={{ marginBottom: 5, display: 'flex', flexDirection: 'row' }}>Tags: {_.get(record, 'tags', []).map(item => (<div style={{ fontWeight: '600', marginLeft: 8 }}>#{getTagName(item)}</div>))}</div>
                {_.get(record, 'isActive', '') === true ?
                  <Tag color="blue">Đang hiển thị</Tag>
                  :
                  <Tag color="red">Đang ẩn</Tag>
                }
                {_.get(record, 'isPopular', '') === true && <Tag color="yellow">Đặt nổi bật</Tag>}
                {_.get(record, 'isSEO', '') === true && <Tag color="orange">SEO</Tag>}<br />
                <Button style={{ marginLeft: -15 }} type="link" icon={<EditOutlined />} onClickCapture={() => openUpdateModal(record.postId)}>Cập nhật</Button>
              </div>
              <div style={{ marginTop: 8 }}>
                <img src={_.get(record, 'photo', '')} style={{ width: 140, borderRadius: 5 }} />
              </div>
            </div>

            {_.get(record, 'isLongform', '') === true && (
              <div className='longform'>
                <Row gutter={24} type="flex" style={{ marginTop: 24, marginBottom: 15 }}>
                  <Col span={12}>
                    <div style={{ fontWeight: 'bold', marginTop: 5, marginLeft: 2 }}>Nội dung<Button
                      style={{ marginLeft: 8 }}
                      type="link"
                      icon={!expandedPostKey.includes(record.postId) ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
                      ghost
                      onClick={() => {
                        if (expandedPostKey.includes(record.postId)) {
                          //remove
                          setExpandedPostKey(expandedPostKey.filter(x => x !== record.postId))
                        } else {
                          //add
                          setExpandedPostKey([...expandedPostKey, record.postId])
                        }
                      }}
                    /></div>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Button
                      onClick={() => {
                        console.log('record: ', record)
                        debugger
                        setPost()
                        let postId = _.get(record, 'postId', '');
                        setIsSection(true);
                        postDetail(postId)
                          .then(result => {
                            debugger
                            setPost(result)
                            setIsCreateSectionModal(true)
                            setLoading(false)
                          })
                          .catch(err => {
                            debugger
                            console.log(err)
                            setLoading(false)
                          })
                      }}
                      type={'primary'}
                      ghost
                      icon={<PlusCircleOutlined />}
                    >Add New Post Section</Button>
                    <Button
                      onClick={() => {
                        setPost()
                        setIsSection(false);
                        let postId = _.get(record, 'postId', '');

                        postDetail(postId)
                          .then(result => {
                            setPost(result)
                            setIsCreateSectionModal(true)
                            setLoading(false)
                          })
                          .catch(err => setLoading(false))
                      }}
                      type={'primary'}
                      icon={<PlusCircleOutlined />}
                      style={{ marginLeft: 15 }}>Add New Post</Button>
                  </Col>
                </Row>
                {expandedPostKey.includes(record.postId) && (
                  _.get(record, 'sections') !== null && _.get(record, 'sections', []).sort((a, b) => {
                    return (a?.no - b?.no)
                  }).filter(x => (x?.sectionId === undefined || x?.sectionId === '')).map(item => {
                    return (
                      <>
                        {/* renderSection */}
                        <Card style={{ marginTop: 10, marginBottom: 10, backgroundColor: '#EDEFF0' }}>
                          <Row gutter={24} type="flex">
                            <Col span={12}>
                              <div style={{ fontWeight: '600', marginTop: 5 }}>{item?.no.toString().toUpperCase()}. {item?.title.toUpperCase()} {item?.isActive === true ? <Tag style={{ marginLeft: 5 }}>Active</Tag> : <Tag color={'red'} style={{ marginLeft: 5 }}>In-active</Tag>}
                                {_.get(record, 'sections', []).filter(x => x?.sectionId === item?.postSectionId).length !== 0 && (
                                  <Button
                                    style={{ marginLeft: 8 }}
                                    type="link"
                                    icon={!expandedSectionKey.includes(item.postSectionId) ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
                                    ghost
                                    onClick={() => {
                                      if (expandedSectionKey.includes(item.postSectionId)) {
                                        //remove
                                        setExpandedSectionKey(expandedSectionKey.filter(x => x !== item.postSectionId))
                                      } else {
                                        //add
                                        setExpandedSectionKey([...expandedSectionKey, item.postSectionId])
                                      }
                                    }}
                                  />
                                )}

                              </div>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                              <Button
                                style={{ marginLeft: 8 }}
                                type="link"
                                icon={<EditOutlined />}
                                ghost
                                onClick={() => {
                                  setPost()
                                  let postId = _.get(record, 'postId', '');

                                  if (item?.sectionId) {
                                    setIsSection(false)
                                  }

                                  postDetail(postId)
                                    .then(result => {
                                      setPost(result)
                                      setPostSection(item)
                                      setIsUpdateSectionModal(true)
                                      setLoading(false)
                                    })
                                    .catch(err => setLoading(false))
                                }}
                              />
                            </Col>
                          </Row>
                        </Card>

                        {/* renderPost */}
                        {expandedSectionKey.includes(item.postSectionId) && (
                          _.get(record, 'sections', []).sort((a, b) => {
                            return (a?.no - b?.no)
                          }).filter(x => x?.sectionId === item?.postSectionId).map(item => {
                            return (
                              <Card style={{ marginTop: 10, marginBottom: 10, paddingLeft: 40 }}>
                                <Row gutter={24} type="flex">
                                  <Col span={12}>
                                    <div style={{ fontWeight: '600', marginTop: 5 }}>{item?.no}. {item?.title} {item?.isActive === true ? <Tag style={{ marginLeft: 5 }}>Active</Tag> : <Tag color={'red'} style={{ marginLeft: 5 }}>In-active</Tag>}</div>
                                  </Col>
                                  <Col span={12} style={{ textAlign: 'right' }}>
                                    <Button
                                      style={{ marginLeft: 8 }}
                                      type="link"
                                      icon={<EditOutlined />}
                                      ghost
                                      onClick={() => {
                                        setPost()
                                        let postId = _.get(record, 'postId', '');

                                        if (item?.sectionId) {
                                          setIsSection(false)
                                        }

                                        postDetail(postId)
                                          .then(result => {
                                            setPost(result)
                                            setPostSection(item)
                                            setIsUpdateSectionModal(true)
                                            setLoading(false)
                                          })
                                          .catch(err => setLoading(false))
                                      }}
                                    />
                                    <Button
                                      style={{ marginLeft: 8 }}
                                      type="link"
                                      icon={<DeleteOutlined />}
                                      ghost
                                      danger
                                      onClick={() => {
                                        Modal.confirm({
                                          title: 'Confirm',
                                          icon: <ExclamationCircleOutlined />,
                                          content: 'Bạn có chắc chắn muốn xoá?',
                                          okText: 'Ok',
                                          cancelText: 'Không',
                                          onOk: () => {
                                            let postId = _.get(record, 'postId', '');
                                            postSectionDelete({ postId, postSectionId: item.postSectionId })
                                              .then(async (result) => {
                                                setLoading(true)
                                                message.success('Section đã được xoá thành công')
                                                const [posts] = await Promise.all([
                                                  postList(),
                                                ]);
                                                setPosts(posts);
                                                setPostsFilter(posts);
                                                setLoading(false)
                                              })
                                              .catch(err => console.log(err))
                                          }
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Card>
                            )
                          })
                        )}
                      </>
                    )
                  })
                )}
              </div>
            )}
          </div>
        )
      }
    },
  ];

  return (
    <Layout className="post-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }}>
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h2>Blog</h2>
                <Checkbox style={{ marginLeft: 20, marginTop: 6 }} defaultChecked={isShowCategory} onChange={(e) => {
                  setIsShowCategory(e.target.checked)
                }}>Hiển thị danh mục con</Checkbox>
              </div>
            </Col>
            <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
              <Button type="primary" onClick={() => setIsCreateModal(true)} icon={<PlusOutlined />}>Thêm mới</Button>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={24}>
              <Search placeholder="input search text" onSearch={(value) => {
                let matchingStrings = []
                if (value) {
                  posts.forEach((item) => {
                    if ((item?.ordinary.toString().toLocaleLowerCase().search(value.toLocaleLowerCase()) > -1) || (item?.title.toLocaleLowerCase().search(value.toLocaleLowerCase()) > -1) || (item.body.toLocaleLowerCase().search(value.toLocaleLowerCase()) > -1)) {
                      matchingStrings.push(item)
                    }
                  })
                  setPostsFilter(matchingStrings);
                } else {
                  setPostsFilter(posts);
                }
              }} style={{ width: 400 }} allowClear enterButton />
            </Col>
          </Row>
        </Card>

        <Tabs defaultActiveKey="1">
          <TabPane tab={`Active ${postsFilter.filter(item => item.isActive === true && item?.category !== process.env.CATEGORY_TUTORIAL_ID).length}`} key="1">
            <Table
              showHeader={false}
              loading={loading}
              bordered
              columns={columns}
              dataSource={postsFilter.filter(item => item.isActive === true && item?.category !== process.env.CATEGORY_TUTORIAL_ID)}
            />
          </TabPane>
          <TabPane tab={`Inactive ${postsFilter.filter(item => item.isActive === false && item?.category !== process.env.CATEGORY_TUTORIAL_ID).length}`} key="2">
            <Table
              showHeader={false}
              loading={loading}
              bordered
              columns={columns}
              dataSource={postsFilter.filter(item => item.isActive === false && item?.category !== process.env.CATEGORY_TUTORIAL_ID)}
            />
          </TabPane>
          <TabPane tab={`Nổi bật ${postsFilter.filter(item => item.isPopular === true && item.isActive === true && item?.category !== process.env.CATEGORY_TUTORIAL_ID).length}`} key="4">
            <Table
              showHeader={false}
              loading={loading}
              bordered
              columns={columns}
              dataSource={postsFilter.filter(item => item.isPopular === true && item.isActive === true && item?.category !== process.env.CATEGORY_TUTORIAL_ID)}
            />
          </TabPane>
          {categories.filter(x => {
            if (isShowCategory) {
              return x.isActive === true
            } else {
              return x.isActive === true && x.categoryParentId === ''
            }
          }).map(cat => {
            return (
              <TabPane tab={`${cat?.categoryParentId === '' ? cat?.name : `+ ${cat?.name}`} ${postsFilter.filter(item => {
                if (item?.category) {
                  if (item?.category === cat.categoryId || categories.filter(x => x.categoryParentId === cat.categoryId).map(y => y.categoryId).includes(item?.category) && item.isActive === true) {
                    return true
                  } else {
                    return false
                  }
                } else {
                  return false
                }
              }).length}`} key={cat.categoryId}>
                <Table
                  showHeader={false}
                  loading={loading}
                  bordered
                  columns={columns}
                  dataSource={postsFilter.filter(item => {
                    if (item?.category) {
                      if (item?.category === cat.categoryId || categories.filter(x => x.categoryParentId === cat.categoryId).map(y => y.categoryId).includes(item?.category) && item.isActive === true) {
                        return true
                      } else {
                        return false
                      }
                    } else {
                      return false
                    }
                  })}
                />
              </TabPane>
            )
          })}
        </Tabs>

        <Modal
          visible={isCreateModal}
          title="Post / Create"
          onCancel={() => {
            Modal.confirm({
              title: 'Bạn có chắc chắn muốn thoát ?',
              icon: null,
              onOk() {
                setIsCreateModal(false)
              },
              okText: 'Yes',
              onCancel() { },
              cancelText: 'No',
            });
          }}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
        >
          <PostCreateForm
            posts={posts}
            categories={categories}
            tags={tags}
            authors={authors}
            onCancel={() => setIsCreateModal(false)}
            onCallback={async () => {
              const [posts] = await Promise.all([
                postList(),
              ]);
              setPosts(posts);
              setPostsFilter(posts);
            }}
          />
        </Modal>
        <Modal
          visible={isUpdateModal}
          title="Post / Update"
          onCancel={() => {
            Modal.confirm({
              title: 'Bạn có chắc chắn muốn thoát ?',
              icon: null,
              onOk() {
                setIsUpdateModal(false)
              },
              okText: 'Yes',
              onCancel() { },
              cancelText: 'No',
            });
          }}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
        >
          <PostUpdateForm
            posts={posts}
            categories={categories}
            tags={tags}
            post={post}
            authors={authors}
            onCancel={() => setIsUpdateModal(false)}
            onCallback={async () => {
              const [posts] = await Promise.all([
                postList(),
              ]);
              setPosts(posts);
              setPostsFilter(posts);
            }}
          />
        </Modal>
        <Modal
          visible={isCreateSectionModal}
          title={`Longform / Create ${isSection === true ? 'Section' : 'Post'}`}
          onCancel={() => {
            Modal.confirm({
              title: 'Bạn có chắc chắn muốn thoát ?',
              icon: null,
              onOk() {
                setIsCreateSectionModal(false)
              },
              okText: 'Yes',
              onCancel() { },
              cancelText: 'No',
            });
          }}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
        >
          <PostSectionCreateForm
            isSection={isSection}
            post={post}
            onCancel={() => setIsCreateSectionModal(false)}
            onCallback={async () => {
              const [posts] = await Promise.all([
                postList(),
              ]);
              setPosts(posts);
              setPostsFilter(posts);
            }}
          />
        </Modal>
        <Modal
          visible={isUpdateSectionModal}
          title={`Longform / Update ${isSection === true ? 'Section' : 'Post'}`}
          onCancel={() => {
            Modal.confirm({
              title: 'Bạn có chắc chắn muốn thoát ?',
              icon: null,
              onOk() {
                setIsUpdateSectionModal(false)
              },
              okText: 'Yes',
              onCancel() { },
              cancelText: 'No',
            });
          }}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
        >
          <PostSectionUpdateForm
            isSection={isSection}
            post={post}
            postSection={postSection}
            onCancel={() => setIsUpdateSectionModal(false)}
            onCallback={async () => {
              const [posts] = await Promise.all([
                postList(),
              ]);
              setPosts(posts);
              setPostsFilter(posts);
            }}
          />
        </Modal>
      </Layout>
    </Layout>
  )
};

export default PostPage;