/* eslint-disable */
import request from "utils/request";

export const gumroadList = async (token) => {
    return request(`admin/gumroads`, {}, { method: "GET", token })
};

export const gumroadWebhookList = async (token) => {
    return request(`admin/gumroads/webhook`, {}, { method: "GET", token })
};

export const gumroadWebhook = async (payload) => {
    return request(`gumroads/webhook`, payload, { method: "POST" })
};

export const gumroadWebhookEnded = async (payload) => {
    return request(`gumroads/webhook_ended`, payload, { method: "POST" })
};

export const gumroadWebhookRestarted = async (payload) => {
    return request(`gumroads/webhook-restarted`, payload, { method: "POST" })
};

export const gumroadCancel = async (payload) => {
    return request(`gumroad/cancel`, payload, { method: "POST" })
};

export const gumroadDeleteAccount = async (payload) => {
    return request(`gumroad/delete-account`, payload, { method: "POST" })
};

export const gumroadMigrationAccount = async (payload) => {
    return request(`gumroad/migration-account`, payload, { method: "POST" })
};

export const gumroadSaleList = async (payload, token) => {
    return request(`admin/gumroads/sales`, payload, { method: "GET", token })
};
